import React from 'react';
import { Grid, Box, makeStyles, Hidden, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import DocsLogo from '../../assets/images/DocsLogo.svg';
import GithubLogo from '../../assets/images/GithubLogo.svg';
import TelegramLogo from '../../assets/images/TelegramLogo.svg';
import TwitterLogo from '../../assets/images/TwitterLogo.svg';
import InstagramLogo from '../../assets/images/InstagramLogo.svg';
import { theme } from '../../App';

const useStyles = makeStyles((theme) => ({
  flexDirection: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      marginTop: 20,
    },
  },
  linkItems: {
    fontWeight: 'bold',
  },
}));

const SocialMediaItems = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Box className={classes.flexDirection}>
      <Box display='flex' mb={1}>
        <img
          src={DocsLogo}
          alt='instagram logo'
          style={{ marginRight: 10 }}
          onClick={() => window.open('https://onerare.gitbook.io/docs/')}
        />
        <Hidden smDown>
          <Link
            style={{ textDecoration: 'none' }}
            onClick={() => window.open('https://onerare.gitbook.io/docs/')}
          >
            <Typography
              variant='body2'
              color='textPrimary'
              className={classes.linkItems}
            >
              DOCS
            </Typography>
          </Link>
        </Hidden>
      </Box>
      <Box display='flex' mb={1}>
        <img
          src={GithubLogo}
          alt='github'
          style={{ marginRight: 10 }}
          onClick={() => window.open('https://github.com/onerare')}
        />
        <Hidden smDown>
          <Link
            onClick={() => window.open('https://github.com/onerare')}
            style={{ textDecoration: 'none' }}
          >
            <Typography
              variant='body2'
              color='textPrimary'
              className={classes.linkItems}
            >
              GITHUB
            </Typography>
          </Link>
        </Hidden>
      </Box>
      <Box display='flex' mb={1}>
        <img
          src={TelegramLogo}
          alt='instagram logo'
          style={{ marginRight: 10 }}
          onClick={() => window.open('https://t.me/onerarenft')}
        />
        <Hidden smDown>
          <Link
            onClick={() => window.open('https://t.me/onerarenft')}
            style={{ textDecoration: 'none' }}
          >
            <Typography
              variant='body2'
              color='textPrimary'
              className={classes.linkItems}
            >
              TELEGRAM
            </Typography>
          </Link>
        </Hidden>
      </Box>
      <Box display='flex' mb={1}>
        <img
          src={TwitterLogo}
          alt='twitter logo'
          style={{ marginRight: 10 }}
          onClick={() => window.open('https://twitter.com/onerarenft')}
        />
        <Hidden smDown>
          <Link
            onClick={() => window.open('https://twitter.com/onerarenft')}
            style={{ textDecoration: 'none' }}
          >
            <Typography
              variant='body2'
              color='textPrimary'
              className={classes.linkItems}
            >
              TWITTER
            </Typography>
          </Link>
        </Hidden>
      </Box>
      <Box display='flex'>
        <img
          src={InstagramLogo}
          alt='instagram logo'
          style={{ marginRight: 10, height: 22 }}
          onClick={() => window.open('https://www.instagram.com/onerarenft/')}
        />
        <Hidden smDown>
          <Link
            onClick={() => window.open('https://www.instagram.com/onerarenft/')}
            style={{ textDecoration: 'none' }}
          >
            <Typography
              variant='body2'
              color='textPrimary'
              className={classes.linkItems}
            >
              INSTAGRAM
            </Typography>
          </Link>
        </Hidden>
      </Box>
    </Box>
  );
};

export default SocialMediaItems;
