import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Container,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import LogoWhite from '../../assets/images/LogoWhite.svg';
import ComingSoonPinkImage from '../../assets/images/ComingSoonPinkImage.svg';
import PotatoIcon from '../../assets/images/PotatoIcon.svg';
import CookingOilIcon from '../../assets/images/CookingOilIcon.svg';
import SaltIcon from '../../assets/images/SaltIcon.svg';
import FriesIcon from '../../assets/images/FriesIcon.svg';
import PlusIcon from '../../assets/images/PlusIcon.svg';
import EqualsIcon from '../../assets/images/EqualsIcon.svg';
import SausagePizzaIcon from '../../assets/images/SausagePizzaIcon.svg';
import { theme } from '../../App';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const PrelaunchIntroductorySection = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const md = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      p='10vw'
      bgcolor='secondary.main'
      color='text.secondary'
      textAlign='center'
    >
      <Container>
        <Box
          height='20vh'
          display='flex'
          alignItems='flex-end'
          justifyContent='center'
        >
          <img
            src={LogoWhite}
            alt='One Rare logo white'
            style={{ height: sm ? 60 : 100 }}
          />
          <img
            src={ComingSoonPinkImage}
            alt='One Rare logo white'
            style={{ height: sm && 100 }}
          />
        </Box>

        <Box my={theme.spacing(1)} mx={!sm && theme.spacing(1)}>
          <Typography variant='body1'>
            Welcome to OneRare, an NFT+Defi gaming platform built with a grant
            from OxPolygon.
          </Typography>
          <br />
          <Typography variant='body1'>
            OneRare allows you to recreate your favorite Dishes & gain exclusive
            bragging rights. Use our Recipes to shop for Ingredient NFTs and
            create Dishes.
          </Typography>
          <br />
          <Typography variant='body1'>
            Each Dish is handcrafted, unique & one-of-a-kind, so you will be the
            sole owner of the Dish, meaning the only French Fries in the world
            belongs to YOU !
          </Typography>
          <br />
          <Typography variant='body1'>
            What’s more, you can stake your Dish NFT in our Food Vaults to
            collect Token Rewards & special Ingredients every week, that help
            you build more Recipes.
          </Typography>
        </Box>

        {!md ? (
          <Box
            display='flex'
            alignItems='center'
            pt={theme.spacing(1)}
            justifyContent='space-between'
          >
            <Box>
              <img src={PotatoIcon} alt='potato' />
              <Typography
                variant='body2'
                style={{ paddingTop: theme.spacing(2) }}
              >
                POTATO
              </Typography>
            </Box>
            <img src={PlusIcon} alt='plus' />
            <Box>
              <img src={CookingOilIcon} alt='cooking oil' />
              <Typography
                variant='body2'
                style={{ paddingTop: theme.spacing(2) }}
              >
                COOKING OIL
              </Typography>
            </Box>
            <img src={PlusIcon} alt='plus' />
            <Box>
              <img src={SaltIcon} alt='salt' />
              <Typography
                variant='body2'
                style={{ paddingTop: theme.spacing(2) }}
              >
                SALT
              </Typography>
            </Box>

            <img src={EqualsIcon} alt='equals' />
            <Box>
              <img src={FriesIcon} alt='french fries' />
            </Box>
          </Box>
        ) : (
          <Box>
            <Box
              display='flex'
              justifyContent={xs ? 'space-between' : 'space-evenly'}
            >
              <Box display='flex' flexDirection='column' alignItems='center'>
                <img src={PotatoIcon} alt='potato' width={sm && 100} />
                <Typography
                  variant='body2'
                  style={{ paddingTop: theme.spacing(2) }}
                >
                  POTATO
                </Typography>
              </Box>
              <Box display='flex' flexDirection='column' alignItems='center'>
                <img src={CookingOilIcon} alt='potato' width={sm && 100} />
                <Typography
                  variant='body2'
                  style={{ paddingTop: theme.spacing(2) }}
                >
                  COOKING OIL
                </Typography>
              </Box>
            </Box>
            <Box display='flex' justifyContent='space-around'></Box>
            <img
              src={PlusIcon}
              alt='plus'
              style={{ paddingTop: theme.spacing(2) }}
            />
            <Box py={theme.spacing(0.5)}>
              <img src={SaltIcon} alt='salt' width={sm && 100} />
              <Typography
                variant='body2'
                style={{ paddingTop: theme.spacing(2) }}
              >
                SALT
              </Typography>
            </Box>
            <Box display='flex' flexDirection='column' alignItems='center'>
              <img
                src={EqualsIcon}
                alt='equals'
                style={{ marginBottom: theme.spacing(5) }}
              />
              <img src={FriesIcon} alt='french fries' />
            </Box>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default PrelaunchIntroductorySection;
