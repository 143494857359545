import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Container,
  Typography,
  useMediaQuery,
  Paper,
  Button,
  Icon,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import PrelaunchBackground from '../../assets/images/PrelaunchBackground.svg';
import PrelaunchCancelIcon from '../../assets/images/PrelaunchCancelIcon.svg';
import ForkIcon from '../../assets/images/ForkIcon.svg';
import SpoonIcon from '../../assets/images/SpoonIcon.svg';
import { theme } from '../../App';
import CustomButton from '../resuableComponents/CustomButton';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${PrelaunchBackground})`,
  },
}));

const PrelaunchClaimTokensSection = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const md = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box px='10vw' py='5vw' bgcolor='secondary.main'>
      <Container
        style={{
          display: 'flex',
          backgroundColor: '#FECEFF',
          borderRadius: 25,
        }}
      >
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          py={theme.spacing(0.2)}
        >
          <img alt='cancel icon' src={PrelaunchCancelIcon} />
          <img alt='cancel icon' src={PrelaunchCancelIcon} />
        </Box>
        <Box
          style={{
            textAlign: 'center',
            paddingTop: theme.spacing(10),
            paddingBottom: theme.spacing(8),
            paddingRight: md && '10vw',
            paddingLeft: md && '10vw',
          }}
        >
          <Typography variant='h1'>Token Claim</Typography>
          <Box textAlign='center' py={theme.spacing(0.5)}>
            <Typography variant='body1'>
              For OneRare, Community comes first.
            </Typography>
            <Typography variant='body1'>
              Before Team, before Investors & before anything else.
            </Typography>
            <br />
            <Typography variant='body1'>
              As a Thank You to our earliest community members, we are giving
              away 500,000 ORE Tokens via Lottery to 2,000 Participants.
            </Typography>
            <br />
            <Typography variant='body1'>
              Each Dish is handcrafted, unique & one-of-a-kind, so you will be
              the sole owner of the Dish, meaning the only French Fries in the
              world belongs to YOU !
            </Typography>
            <br />
            <Typography variant='body1'>
              Complete all Tasks to gain maximum entries in the draw.
            </Typography>
          </Box>
          <Button
            variant='contained'
            fullWidth
            style={{
              display: 'flex',
              padding: '10px 0px',
              justifyContent: 'space-between',
              borderRadius: '15px',
              backgroundColor: 'black',
            }}
          >
            <Icon style={{ width: 'unset' }}>
              <img src={SpoonIcon} alt='spoon' />
            </Icon>
            <Typography variant='subtitle1' style={{ color: '#D1FF1A' }}>
              CLAIM TOKENS
            </Typography>
            <Icon style={{ width: 'unset' }}>
              <img src={ForkIcon} alt='fork' />
            </Icon>
          </Button>
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          py={theme.spacing(0.2)}
        >
          <img alt='cancel icon' src={PrelaunchCancelIcon} />
          <img alt='cancel icon' src={PrelaunchCancelIcon} />
        </Box>
      </Container>
    </Box>
  );
};

export default PrelaunchClaimTokensSection;
