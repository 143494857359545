import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Button,
  Typography,
  useMediaQuery,
  TextField,
  Divider,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { theme } from '../../App';
import { selectReward } from '../../reducers/UiReducer';

const useStyles = makeStyles((theme) => ({
  container: {},
  root: {
    borderRadius: 0,
    textTransform: 'none',
    boxShadow: 'none',
  },
}));

const RewardsTabSection = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { selectedReward } = store.ui;
  const matches = useMediaQuery('(min-width:940px)');
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box mb={!matches ? theme.spacing(0.5) : theme.spacing(1.3)}>
      <Grid container justify='space-between'>
        <Grid item xs={6}>
          <Button
            variant='contained'
            fullWidth
            classes={{ root: classes.root }}
            style={{
              backgroundColor:
                selectedReward === 'Ingredients'
                  ? theme.palette.primary.main
                  : '#C4C4C4',
            }}
            onClick={() => dispatch(selectReward('Ingredients'))}
          >
            <Typography
              variant='h3'
              style={{
                color: selectedReward !== 'Ingredients' && '#6F6F6F',
                fontSize: sm && !xs ? '1.25rem' : xs ? '1rem' : '1.75rem',
              }}
            >
              Ingredients
            </Typography>
          </Button>
        </Grid>

        <Grid item xs={6}>
          <Box pl={theme.spacing(0.05)}>
            <Button
              variant='contained'
              fullWidth
              classes={{ root: classes.root }}
              style={{
                backgroundColor:
                  selectedReward === 'Tokens'
                    ? theme.palette.primary.main
                    : '#C4C4C4',
              }}
              onClick={() => dispatch(selectReward('Tokens'))}
            >
              <Typography
                variant='h3'
                style={{
                  color: selectedReward !== 'Tokens' && '#6F6F6F',
                  fontSize: sm && !xs ? '1.25rem' : xs ? '1rem' : '1.75rem',
                }}
              >
                Tokens
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RewardsTabSection;
