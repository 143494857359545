import React from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import SplashImagePrelaunch from '../assets/images/SplashImagePrelaunch.svg';
import lottieData from '../assets/images/lottieData';
import PrelaunchFAQSection from '../components/prelaunchHomeComponents/PrelaunchFAQSection';
import PrelaunchIntroductorySection from '../components/prelaunchHomeComponents/PrelaunchIntroductorySection';
import PrelaunchClaimTokensSection from '../components/prelaunchHomeComponents/PrelaunchClaimTokensSection';
import Lottie from 'react-lottie';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const HomePage = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Box bgcolor='black'>
      <Lottie options={defaultOptions} height={'100%'} width={'100%'} />
      <PrelaunchIntroductorySection />
      <PrelaunchClaimTokensSection />
      <PrelaunchFAQSection />
    </Box>
  );
};

export default HomePage;
