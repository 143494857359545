import React from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Meta from './Meta';
import Header from './Header';
import Footer from './Footer';
import LoaderImage from '../../assets/images/LoaderImage.svg';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: '#fff',
  },
}));

const Layout = ({ children, noContainer, title, description, keywords }) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  const { loading } = store.ui;

  return (
    <>
      <Meta title={title} description={description} keywords={keywords} />
      <Header />
      <main>{noContainer ? children : <Container>{children}</Container>}</main>
      <Footer />
      {loading && (
        <Backdrop className={classes.backdrop} open={loading}>
          <object
            type='image/svg+xml'
            data={LoaderImage}
            height={200}
            width={200}
          >
            <img
              src={LoaderImage}
              alt='stirring coffee'
              height={200}
              width={200}
            />
          </object>
        </Backdrop>
      )}
    </>
  );
};

export default Layout;
