import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce } from 'lodash';
import IngredientsIntroSection from '../components/IngredientsComponents/IngredientsIntroSection';
import TabSection from '../components/IngredientsComponents/IngredientsTabSection';
import FilterSection from '../components/IngredientsComponents/IngredientsFilterSection';
import ItemsSection from '../components/IngredientsComponents/IngredientsItemsSection';
import {
  getIngredientsDispatch,
  searchIngredientsThunk,
  sortIngredientsThunk,
} from '../reducers/UiReducer';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const IngredientsPage = () => {
  const store = useSelector((state) => state);
  const [selectedSortCriteria, setSelectedSortCriteria] = useState('');
  const [searchTerm, setSearchTerm] = useState(null);
  const [currPageNum, setCurrPageNumber] = useState(1);
  const { ingredientsList, selectedIngredientTab } = store.ui;
  const dispatch = useDispatch();
  const classes = useStyles();

  const debouncedSearch = useMemo(
    () =>
      debounce(
        (_searchTerm, _pageNumber) =>
          dispatch(
            searchIngredientsThunk({
              searchTerm: _searchTerm,
              pageNumber: _pageNumber,
            })
          ),
        200
      ),
    [searchTerm, currPageNum]
  );

  useEffect(() => {
    if (selectedSortCriteria)
      dispatch(
        sortIngredientsThunk({
          sortCriteria: selectedSortCriteria,
          pageNumber: 1,
        })
      );
    else dispatch(getIngredientsDispatch(1));
    setCurrPageNumber(1);
  }, [selectedSortCriteria]);

  useEffect(() => {
    if (searchTerm !== null) {
      if (searchTerm) debouncedSearch(searchTerm, 1);
      else dispatch(getIngredientsDispatch(1));
      setCurrPageNumber(1);
    }
    return () => debouncedSearch.cancel();
  }, [searchTerm]);

  const getIngredientsList = () => {
    switch (selectedIngredientTab) {
      case 'All Ingredients':
        return ingredientsList.list;
      case 'Farmers':
        return ingredientsList.list.filter((ingr) => ingr.type == 1);
      case 'Specials':
        return ingredientsList.list.filter((ingr) => ingr.type == 2);
    }
  };

  const handleLoadMore = () => {
    if (searchTerm) {
      debouncedSearch(searchTerm, currPageNum + 1);
    } else if (selectedSortCriteria) {
      dispatch(
        sortIngredientsThunk({
          sortCriteria: selectedSortCriteria,
          pageNumber: currPageNum + 1,
        })
      );
    } else {
      dispatch(getIngredientsDispatch(currPageNum + 1));
    }
    setCurrPageNumber(currPageNum + 1);
  };

  return (
    <Box px='7vw' py='5vh'>
      <IngredientsIntroSection />
      <TabSection />
      <FilterSection
        setSelectedSortCriteria={setSelectedSortCriteria}
        setSearchTerm={setSearchTerm}
      />
      <InfiniteScroll
        next={handleLoadMore}
        dataLength={getIngredientsList().length}
        hasMore={ingredientsList.hasMore}
        style={{ overflow: 'scroll', overflowY: 'hidden' }}
      >
        <ItemsSection
          ingredientsList={getIngredientsList()}
          selectedSortCriteria={selectedSortCriteria}
        />
      </InfiniteScroll>
    </Box>
  );
};

export default IngredientsPage;
