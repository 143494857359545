import React, { useEffect } from 'react';
import {
  Grid,
  Box,
  makeStyles,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import RewardsItem from './RewardsItem';
import { theme } from '../../App';
import CustomButton from '../resuableComponents/CustomButton';
import { useMetamask } from '../hooks/useMetamask';
import {
  getRewardsBalance,
  setConfirmTransactionModal,
  setError,
  setLoading,
} from '../../reducers/UiReducer';
import stakingService from '../../services/stakingService';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const RewardsItemsSection = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { rewardsList, selectedReward, rewardsBalance } = store.ui;
  const matches = useMediaQuery('(min-width:940px)');
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const { active, tried } = useMetamask();

  useEffect(() => {
    if (active && tried) {
      dispatch(getRewardsBalance());
    }
  }, [active, tried]);

  const claimToken = async () => {
    try {
      if (rewardsBalance > 0) {
        dispatch(setConfirmTransactionModal());
        const rewardTx = await stakingService.claimRewards();
        dispatch(setConfirmTransactionModal());
        dispatch(setLoading(true));
        await rewardTx.wait();
        dispatch(getRewardsBalance());
      }
    } catch (err) {
      dispatch(setConfirmTransactionModal());
      dispatch(setError(true));
    }
    dispatch(setLoading(false));
  };

  return (
    <Grid container spacing={!xs && 10}>
      {selectedReward === 'Ingredients' ? (
        rewardsList.map((item, index) => (
          <Grid item xs={6} sm={4} md={3} key={item.name}>
            <RewardsItem item={item} index={index} />
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <Box
              display='flex'
              flexDirection={xs ? 'column' : 'row'}
              alignItems='center'
              mb={20}
            >
              <Typography variant='h4' color='initial'>
                YOUR UNCLAIMED TOKENS
              </Typography>
              <Typography variant='h4' color='initial'>
                &nbsp;{`${rewardsBalance}`}
              </Typography>
            </Box>
            <CustomButton
              style={{
                marginBottom: theme.spacing(1.5),
                backgroundColor: '#000000',
              }}
              textStyle={{
                color: theme.palette.primary.main,
                paddingRight: sm ? 5 : 20,
                paddingLeft: sm ? 5 : 20,
                fontSize: xs && 12,
              }}
              onClick={claimToken}
              disabled={rewardsBalance == 0}
            >
              Claim
            </CustomButton>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default RewardsItemsSection;
