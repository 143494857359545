import React, { useEffect } from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import RecipeSelectedIntroSection from '../components/recipesComponents/RecipeSelectedIntroSection';
import LetsGetStartedSection from '../components/recipesComponents/LetsGetStartedSection';
import CombineIngredientsSection from '../components/recipesComponents/CombineIngredientsSection';
import { useParams } from 'react-router-dom';
import { getRecipieById, getRecipieIngredients } from '../reducers/UiReducer';
import Error404 from './Error404Page';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const RecipeSelectedPage = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const { selectedRecipe, error } = store.ui;
  const classes = useStyles();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getRecipieById(id));
  }, []);

  if (error) return <Error404 />;

  return (
    <Box px='7vw' py='5vh'>
      <RecipeSelectedIntroSection
        selectedRecipe={selectedRecipe}
        recipeIngredients={selectedRecipe.ingredients}
      />
      <LetsGetStartedSection recipeIngredients={selectedRecipe.ingredients} />
      <CombineIngredientsSection selectedRecipe={selectedRecipe} />
    </Box>
  );
};

export default RecipeSelectedPage;
