import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Container,
  Typography,
  Divider,
  Hidden,
  useMediaQuery,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { theme } from '../../App';
import RecipeBookImage from '../../assets/images/RecipeBookImage.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'black',
    height: 2,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

const RecipesIntroSection = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      container
      justify='space-between'
      style={{ paddingBottom: '15vh', paddingTop: '10vh' }}
    >
      <Grid item xs={12} md={7}>
        <Box>
          <Typography variant='h1' style={{ textAlign: sm && 'center' }}>
            Kitchen
          </Typography>
          <Hidden smDown>
            <Divider classes={{ root: classes.root }} />
          </Hidden>
          <Hidden mdUp>
            <object
              type='image/svg+xml'
              data={RecipeBookImage}
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              <img src={RecipeBookImage} alt='recipe book' />
            </object>
          </Hidden>
          <Typography
            variant='body1'
            style={{ paddingRight: theme.spacing(1) }}
          >
            The Kitchen is the heart of OneRare, celebrating Dishes from all
            over the world. Here you will find the famed recipes that will help
            you claim your favorite Dish. Follow Recipes & collect ingredients
            from our foodverse. Once your basket is full, come back to the
            kitchen and combine the ingredients to unlock your Dish. Let’s cook
            up an NFT!
          </Typography>
        </Box>
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} sm={4}>
          <object type='image/svg+xml' data={RecipeBookImage}>
            <img src={RecipeBookImage} alt='recipe book' />
          </object>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default RecipesIntroSection;
