import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Container,
  Typography,
  Divider,
  Hidden,
  useMediaQuery,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { theme } from '../../App';
import OvenImage from '../../assets/images/OvenImage.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'black',
    height: 2,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

const StakesIntroSection = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      container
      justify='space-between'
      style={{ paddingBottom: '15vh', paddingTop: '10vh' }}
    >
      <Grid item xs={12} md={7}>
        <Box>
          <Typography variant='h1' style={{ textAlign: sm && 'center' }}>
            Playground
          </Typography>
          <Hidden smDown>
            <Divider classes={{ root: classes.root }} />
          </Hidden>
          <Hidden mdUp>
            <object
              type='image/svg+xml'
              data={OvenImage}
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              <img src={OvenImage} alt='oven' />
            </object>
          </Hidden>
          <Typography
            variant='body1'
            style={{ paddingRight: theme.spacing(1) }}
          >
            The swings have been ordered, the grass is getting greener - Our
            Playground is coming soon!
          </Typography>
          <Typography
            variant='body1'
            style={{ paddingRight: theme.spacing(1) }}
          >
            The Playground will offer exciting games for OneRare players who own
            Ingredient and Dish NFTs. Try your luck on the first-ever NFT slot
            machine, or battle it out on your Foodtruck - there’s plenty to play
            and win.
          </Typography>
        </Box>
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} sm={4}>
          <object type='image/svg+xml' data={OvenImage}>
            <img src={OvenImage} alt='oven' />
          </object>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default StakesIntroSection;
