import React from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import RewardsIntroSection from '../components/rewardsComponents/RewardsIntroSection';
import RewardsTabSection from '../components/rewardsComponents/RewardsTabSection';
import RewardsItemsSection from '../components/rewardsComponents/RewardsItemsSection';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const RewardsPage = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Box px='7vw' py='5vh'>
      <RewardsIntroSection />
      <RewardsTabSection />
      <RewardsItemsSection />
    </Box>
  );
};

export default RewardsPage;
