import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Typography,
  Divider,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import CustomButton from '../resuableComponents/CustomButton';
import { theme } from '../../App';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'black',
    height: 2,
    width: 180,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'black',
  },
}));

const RecipesItem = ({ item }) => {
  const classes = useStyles();
  const history = useHistory();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      textAlign='center'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <object type='image/svg+xml' data={item.image}>
        <img
          src={item.image}
          alt={item.name}
          style={{
            marginBottom: theme.spacing(1.5),
            width: xs ? '80%' : '100%',
            height: 180,
          }}
        />
      </object>

      <Tooltip
        title={item.name}
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <Typography variant='h4' style={{ paddingTop: theme.spacing(3) }}>
          {item.name.toUpperCase()}
        </Typography>
      </Tooltip>
      <CustomButton
        style={{
          marginBottom: theme.spacing(3),
          marginTop: theme.spacing(1.5),
          backgroundColor: '#000000',
        }}
        textStyle={{
          color: theme.palette.primary.main,
          paddingRight: sm ? 5 : 20,
          paddingLeft: sm ? 5 : 20,
          fontSize: xs && 12,
        }}
        onClick={() => history.push(`/recipes/${item.tokenId}`)}
      >
        View Recipe
      </CustomButton>
    </Box>
  );
};

export default RecipesItem;
