import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Button,
  Typography,
  useMediaQuery,
  TextField,
  Divider,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { theme } from '../../App';
import { selectIngredientTab } from '../../reducers/UiReducer';
import { TAB_NAMES } from '../../config';

const useStyles = makeStyles((theme) => ({
  container: {},
  root: {
    borderRadius: 0,
    textTransform: 'none',
    boxShadow: 'none',
  },
}));

const IngredientsTabSection = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { selectedIngredientTab } = store.ui;
  const matches = useMediaQuery('(min-width:940px)');
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

	const changeTab=(tabName)=>{
		dispatch(selectIngredientTab(tabName));
	}

  return (
    <Box>
      <Grid container justify='space-between'>
        <Grid item xs={4}>
          <Button
            variant='contained'
            fullWidth
            classes={{ root: classes.root }}
            style={{
              backgroundColor:
                selectedIngredientTab === TAB_NAMES.AllIngredients
                  ? theme.palette.primary.main
                  : '#C4C4C4',
            }}
            onClick={()=>changeTab(TAB_NAMES.AllIngredients)}
          >
            <Typography
              variant='h3'
              style={{
                color: selectedIngredientTab !== TAB_NAMES.AllIngredients && '#6F6F6F',
                fontSize: sm && !xs ? '1.25rem' : xs ? '1rem' : '1.75rem',
              }}
            >
              All {matches && 'Ingredients'}
            </Typography>
          </Button>
        </Grid>

        <Grid item xs={4}>
          <Box px={theme.spacing(0.05)}>
            <Button
              variant='contained'
              fullWidth
              classes={{ root: classes.root }}
              style={{
                backgroundColor:
                  selectedIngredientTab === TAB_NAMES.Farmers
                    ? theme.palette.primary.main
                    : '#C4C4C4',
              }}
              onClick={()=>changeTab(TAB_NAMES.Farmers)}
            >
              <Typography
                variant='h3'
                style={{
                  color: selectedIngredientTab !== TAB_NAMES.Farmers && '#6F6F6F',
                  fontSize: sm && !xs ? '1.25rem' : xs ? '1rem' : '1.75rem',
                }}
              >
                Farmers
              </Typography>
            </Button>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Button
            variant='contained'
            fullWidth
            classes={{ root: classes.root }}
            style={{
              backgroundColor:
                selectedIngredientTab === TAB_NAMES.Specials
                  ? theme.palette.primary.main
                  : '#C4C4C4',
            }}
            onClick={()=>changeTab(TAB_NAMES.Specials)}
          >
            <Typography
              variant='h3'
              style={{
                color: selectedIngredientTab !== TAB_NAMES.Specials && '#6F6F6F',
                fontSize: sm && !xs ? '1.25rem' : xs ? '1rem' : '1.75rem',
              }}
            >
              Specials
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default IngredientsTabSection;
