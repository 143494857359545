import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Typography,
  Divider,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import CustomButton from '../resuableComponents/CustomButton';
import { theme } from '../../App';
import { useHistory } from 'react-router-dom';
import {
  addToPurchasedIngredients,
  setConfirmTransactionModal,
  setError,
  setLoading,
  setModalOpen,
} from '../../reducers/UiReducer';
import gameService from '../../services/gameService';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'black',
    height: 2,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'black',
  },
}));

const IngredientsItem = ({ item, index, disabled }) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  const buyItem = async () => {
    try {
      dispatch(setConfirmTransactionModal());
      const purchaseTx = await gameService.purchaseIngredient(
        item.type,
        item.tokenId,
        item.price
      );
      dispatch(setConfirmTransactionModal());
      dispatch(setLoading(true));
      await purchaseTx.wait();
      dispatch(addToPurchasedIngredients(item));
    } catch (err) {
      dispatch(setConfirmTransactionModal());
      dispatch(setError(true));
    }
    dispatch(setModalOpen());
    dispatch(setLoading(false));
  };

  return (
    <Box
      textAlign='center'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <object
        type='image/svg+xml'
        data={item.image}
        style={{ height: 180, width: xs ? '80%' : '100%' }}
      >
        <img
          src={item.image}
          alt={item.name}
          style={{
            marginBottom: theme.spacing(1.5),
            width: xs ? '80%' : '100%',
            height: 180,
          }}
        />
      </object>

      <Tooltip
        title={item.name}
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <Typography variant='h4' style={{ marginTop: theme.spacing(3) }}>
          {item.name}
        </Typography>
      </Tooltip>
      <Divider
        classes={{ root: classes.root }}
        style={{
          width: xs ? '80%' : '100%',
          marginBottom: theme.spacing(1),
          marginTop: theme.spacing(1),
        }}
      />
      <Typography
        variant='subtitle1'
        style={{ marginBottom: theme.spacing(2.5) }}
      >
        {item.price}
      </Typography>
      <CustomButton
        style={{
          marginBottom: theme.spacing(1.5),
          backgroundColor: '#000000',
        }}
        textStyle={{
          color: theme.palette.primary.main,
          paddingRight: sm ? 5 : 20,
          paddingLeft: sm ? 5 : 20,
          fontSize: xs && 12,
        }}
        onClick={buyItem}
        disabled={disabled}
      >
        BUY NOW
      </CustomButton>
      <CustomButton
        onClick={() => history.push(`/ingredients/${item.tokenId}`)}
        info
        style={{ backgroundColor: '#b2b3b3' }}
        textStyle={{
          color: '#fff',
          paddingRight: sm ? 5 : 20,
          paddingLeft: sm ? 5 : 20,
          fontSize: xs && 12,
        }}
      >
        VIEW INFO
      </CustomButton>
    </Box>
  );
};

export default IngredientsItem;
