import { ethers } from "ethers";
import ethersServiceProvider from "./ethersServiceProvider";

export class GameService{

	contractAddr=process.env.REACT_APP_GAME_CONTRACT;

	 constructor(){}

	 async purchaseIngredient(type,tokenId,price){
		try{
			let purchaseTx; 
			const tokenPrice=await ethersServiceProvider.getCurrentPrice(tokenId);
			console.log(tokenPrice.toString(),"token price");
			const tx=await ethersServiceProvider.approveUSDCDelegation(tokenPrice[0][2]);//2 index for USDC coin
			await tx.wait();
			if(type==="1"){
				purchaseTx=await ethersServiceProvider.buyFixedPriceIngredient(tokenId,2); //2 index for USDC coin
			}else if(type==="2"){
				purchaseTx=await ethersServiceProvider.buyVariablePriceIngredient(tokenId,2); //2 index for USDC coin
			}
			return purchaseTx;
		}catch(err){
			console.log(err,"Error in purchasing ingredients");
			throw err;
		}
	 }

	 async cookDish(tokenId,data){
		 try{
			const isApproved=await ethersServiceProvider.isApprovalForAll(this.contractAddr);
			if(!isApproved[0]){
				const tx=await ethersServiceProvider.approveERC1155Delegation(this.contractAddr);
				await tx.wait();
			}
			const cookTx=await ethersServiceProvider.cookDish(tokenId,data);
			return cookTx;
		 }catch(err){
			console.log(err,"Error in cooking Dish");
			throw err;
		 }
	 }
}

const gameService = new GameService();

export default gameService;