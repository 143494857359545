import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Container,
  Typography,
  Button,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Full from '../../assets/images/Full.svg';
import LogoBlack from '../../assets/images/LogoBlack.svg';
import CartIcon from '../../assets/images/CartIcon.svg';
import BowlIcon from '../../assets/images/BowlIcon.svg';
import OvenIcon from '../../assets/images/OvenIcon.svg';
import FoodItemsIcon from '../../assets/images/FoodItemsIcon.svg';
import FrenchFriesIcon from '../../assets/images/FrenchFriesIcon.svg';
import HanoiPhoIcon from '../../assets/images/HanoiPhoIcon.svg';
import PepperoniPizzaIcon from '../../assets/images/PepperoniPizzaIcon.svg';
import { theme } from '../../App';
import CustomButton from '../resuableComponents/CustomButton';
import { Link as RouterLink, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${Full})`,
  },
}));

const IntroductorySection = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box p='10vw' className={classes.container}>
      <Container>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Box textAlign='center' maxWidth='700px' mb={8}>
            <img
              src={LogoBlack}
              alt='One Rare logo black'
              style={{ maxWidth: '250px', marginBottom: theme.spacing(6) }}
            />
            <Typography variant='h6' style={{ marginBottom: theme.spacing(8) }}>
              OneRare is the world's first Metaverse for the global Food &
              Beverage Industry. We are building the first tokenization layer
              that celebrates Food in Web3 - creating an immersive & gamified
              experience for users worldwide.
            </Typography>
          </Box>

          <Grid
            container
            justify='space-between'
            style={{ marginBottom: theme.spacing(14) }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              style={{ marginBottom: theme.spacing(4) }}
            >
              <Box textAlign='center'>
                <img
                  src={CartIcon}
                  alt='cart icon'
                  style={{ marginBottom: theme.spacing(4) }}
                />
                <Typography variant='subtitle1' style={{ lineHeight: 1.25 }}>
                  VIEW DISHES &
                  <br />
                  RECIPES
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              style={{ marginBottom: theme.spacing(4) }}
            >
              <Box textAlign='center'>
                <img
                  src={BowlIcon}
                  alt='bowl icon'
                  style={{ marginBottom: theme.spacing(4) }}
                />
                <Typography variant='subtitle1' style={{ lineHeight: 1.25 }}>
                  COLLECT REQUIRED
                  <br />
                  INGREDIENTS
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              style={{ marginBottom: theme.spacing(4) }}
            >
              <Box textAlign='center'>
                <img
                  src={OvenIcon}
                  alt='oven icon'
                  style={{ marginBottom: theme.spacing(4) }}
                />
                <Typography variant='subtitle1' style={{ lineHeight: 1.25 }}>
                  COMBINE TO
                  <br />
                  MAKE DISHES
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              style={{ marginBottom: theme.spacing(4) }}
            >
              <Box textAlign='center'>
                <img
                  src={FoodItemsIcon}
                  alt='food items icon'
                  style={{ marginBottom: theme.spacing(4) }}
                />
                <Typography variant='subtitle1' style={{ lineHeight: 1.25 }}>
                  USE NFTS TO
                  <br />
                  PLAY GAMES
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box textAlign='center'>
            <Typography variant='h1' style={{ marginBottom: theme.spacing(8) }}>
              Recipes
            </Typography>
          </Box>
          <Grid
            container
            justify='space-between'
            style={{ marginBottom: theme.spacing(5) }}
          >
            <Grid item xs={12} lg={4} md={6} sm={12}>
              <Box
                textAlign='center'
                display='flex'
                flexDirection='column'
                alignItems='center'
              >
                <object
                  type='image/svg+xml'
                  data={FrenchFriesIcon}
                  style={{
                    marginBottom: theme.spacing(3),
                    height: 250,
                    width: 250,
                  }}
                >
                  <img
                    src={FrenchFriesIcon}
                    alt='french fries'
                    style={{
                      marginBottom: theme.spacing(3),
                      height: 250,
                      width: 250,
                    }}
                  />
                </object>
                <Typography
                  variant='h4'
                  style={{
                    marginBottom: theme.spacing(3),
                  }}
                >
                  FRENCH FRIES
                </Typography>
                <CustomButton
                  style={{
                    backgroundColor: '#000000',
                    marginBottom: theme.spacing(4),
                  }}
                  textStyle={{ color: '#ffffff' }}
                  onClick={() => history.push(`/recipes/16`)}
                >
                  VIEW RECIPE
                </CustomButton>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4} md={6} sm={12}>
              <Box
                textAlign='center'
                display='flex'
                flexDirection='column'
                alignItems='center'
              >
                <object
                  type='image/svg+xml'
                  data={PepperoniPizzaIcon}
                  style={{
                    marginBottom: theme.spacing(3),
                    height: 250,
                    width: 250,
                  }}
                >
                  <img
                    src={PepperoniPizzaIcon}
                    alt='cart icon'
                    style={{
                      marginBottom: theme.spacing(3),
                      height: 250,
                      width: 250,
                    }}
                  />
                </object>
                <Typography
                  variant='h4'
                  style={{
                    marginBottom: theme.spacing(3),
                  }}
                >
                  PEPPERONI PIZZA
                </Typography>
                <CustomButton
                  style={{
                    backgroundColor: '#000000',
                    marginBottom: theme.spacing(4),
                  }}
                  textStyle={{ color: '#ffffff' }}
                  onClick={() => history.push(`/recipes/17`)}
                >
                  VIEW RECIPE
                </CustomButton>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4} md={6} sm={12}>
              <Box
                textAlign='center'
                display='flex'
                flexDirection='column'
                alignItems='center'
              >
                <object
                  type='image/svg+xml'
                  data={HanoiPhoIcon}
                  style={{
                    marginBottom: theme.spacing(3),
                    height: 250,
                    width: 250,
                  }}
                >
                  <img
                    src={HanoiPhoIcon}
                    alt='hanoi pho'
                    style={{
                      marginBottom: theme.spacing(3),
                      height: 250,
                      width: 250,
                    }}
                  />
                </object>
                <Typography
                  variant='h4'
                  style={{
                    marginBottom: theme.spacing(3),
                  }}
                >
                  HANOI PHO
                </Typography>
                <CustomButton
                  style={{
                    backgroundColor: '#000000',
                    marginBottom: theme.spacing(4),
                  }}
                  textStyle={{ color: '#ffffff' }}
                  onClick={() => history.push(`/recipes/18`)}
                >
                  VIEW RECIPE
                </CustomButton>
              </Box>
            </Grid>
          </Grid>
          <Button
            variant='outlined'
            style={{
              backgroundColor: 'white',
              paddingLeft: '2rem',
              paddingRight: '2rem',
              borderRadius: '40px',
              border: '1px solid black',
            }}
            component={RouterLink}
            to='/recipes'
          >
            See more
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default IntroductorySection;
