import React from 'react';
import { Grid, Box, makeStyles, useMediaQuery } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import IngredientsItem from './IngredientsItem';
import { theme } from '../../App';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const IngredientsItemsSection = ({ ingredientsList }) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { purchasedIngredient } = store.ui;
  const matches = useMediaQuery('(min-width:940px)');
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  if (purchasedIngredient.length > 0) {
    console.log(purchasedIngredient, 'purchasedIngredient');
  }
  return (
    <Grid container spacing={!xs && 10}>
      {ingredientsList.map((item, index) => (
        <Grid item xs={6} sm={6} md={4} key={item.id}>
          <IngredientsItem
            item={item}
            index={index}
            disabled={
              purchasedIngredient.filter((ingr) => ingr.tokenId == item.tokenId)
                .length > 0
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default IngredientsItemsSection;
