import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Container,
  Typography,
  Divider,
  Hidden,
  useMediaQuery,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { theme } from '../../App';
import IcecreamImage from '../../assets/images/IcecreamImage.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'black',
    height: 2,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

const RewardsIntroSection = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      container
      justify='space-between'
      style={{ paddingBottom: '15vh', paddingTop: '10vh' }}
    >
      <Grid item xs={12} md={7}>
        <Box>
          <Typography variant='h1' style={{ textAlign: sm && 'center' }}>
            Rewards
          </Typography>
          <Hidden smDown>
            <Divider classes={{ root: classes.root }} />
          </Hidden>
          <Hidden mdUp>
            <object
              type='image/svg+xml'
              data={IcecreamImage}
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              <img src={IcecreamImage} alt='ice cream' />
            </object>
          </Hidden>
          <Typography
            variant='body1'
            style={{ paddingRight: theme.spacing(1) }}
          >
            Everyone deserves a Dessert at the end of a hearty meal ! Accrue
            rewards from our Playground games. Earn ORARE Tokens and Special
            Ingredients that help you make more Recipes. Claimed Ingredients
            will make their way to your Dashboard, and claimed Tokens will be
            added to your wallet.
          </Typography>
          <Typography
            variant='body1'
            style={{ paddingRight: theme.spacing(1) }}
          >
            Coming Soon !
          </Typography>
        </Box>
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} sm={4}>
          <object
            type='image/svg+xml'
            data={IcecreamImage}
            style={{ height: 300 }}
          >
            <img src={IcecreamImage} alt='ice cream' />
          </object>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default RewardsIntroSection;
