import React from 'react';
import { Grid, Box, makeStyles, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { theme } from '../App';
import AdminTabSection from '../components/adminComponents/AdminTabSection';
import AdminIntroSection from '../components/adminComponents/AdminIntroSection';
import AdminItemsSection from '../components/adminComponents/AdminItemsSection';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const AdminPage = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Box px='7vw' py='5vh'>
      <AdminIntroSection />
      <AdminTabSection />
      <AdminItemsSection />
    </Box>
  );
};

export default AdminPage;
