import React, { useEffect } from 'react';
import { Grid, Box, makeStyles, useMediaQuery } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import StakeItem from './StakeItem';
import { theme } from '../../App';
import { getStakedTokens } from '../../reducers/UiReducer';
import { useMetamask } from '../hooks/useMetamask';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const StakeItemsSection = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { purchasedRecipe,stakedTokens } = store.ui;
  const matches = useMediaQuery('(min-width:940px)');
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
	const {active,tried}=useMetamask();

	useEffect(()=>{
		if(stakedTokens===null && active && tried){
			dispatch(getStakedTokens());
		}
	},[active,tried]);

  return (
    <Grid container spacing={!xs && 10}>
			{
				stakedTokens && stakedTokens.map((item,index)=>(
					<Grid item xs={12} sm={6} md={4} key={item.name}>
						<StakeItem item={item} index={index} staked={true}/>
					</Grid>
				))
			}
      {purchasedRecipe.map((item, index) => (
        <Grid item xs={12} sm={6} md={4} key={item.name}>
          <StakeItem item={item} index={index} staked={false}/>
        </Grid>
      ))}
    </Grid>
  );
};

export default StakeItemsSection;
