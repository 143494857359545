import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Button,
  useMediaQuery,
  Icon,
  Typography,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { theme } from '../../App';
import ButtonBackground from '../../assets/images/ButtonBackground.svg';
import DisabledButtonBackground from '../../assets/images/DisabledButtonBackground.svg';
import SpoonIcon from '../../assets/images/SpoonIcon.svg';
import ForkIcon from '../../assets/images/ForkIcon.svg';
import DisabledSpoonIcon from '../../assets/images/DisabledSpoonIcon.svg';
import DisabledForkIcon from '../../assets/images/DisabledForkIcon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: '10px 0px',
    borderRadius: '40px',
    [theme.breakpoints.down('xs')]: {
      padding: '5px 0px',
    },
  },
}));

const CustomButton = ({
  children,
  style,
  textStyle,
  info,
  onClick,
  disabled,
  staked,
  fullwidth,
}) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Button
      variant='contained'
      fullwidth={fullwidth && true}
      classes={{ root: classes.root }}
      style={{
        backgroundColor: info || disabled || staked ? '#b2b3b3' : '#000000',
        ...style,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon style={{ width: 'unset' }}>
        <img
          src={info ? DisabledSpoonIcon : SpoonIcon}
          alt='spoon'
          width={30}
          height={25}
        />
      </Icon>
      <Typography
        variant='body2'
        style={{
          paddingRight: 20,
          paddingLeft: 20,
          color: info || disabled || staked ? '#fff' : '#D1FF1A',
          ...textStyle,
        }}
      >
        {children}
      </Typography>

      <Icon style={{ width: 'unset' }}>
        <img
          src={info ? DisabledForkIcon : ForkIcon}
          alt='fork'
          width={30}
          height={25}
        />
      </Icon>
    </Button>
  );
};

export default CustomButton;
