import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Container,
  Typography,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { theme } from '../../App';

const useStyles = makeStyles((theme) => ({
  fontSize: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
    },
  },
}));

const FAQSection = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Box p='10vw' bgcolor='secondary.main'>
      <Container>
        <Box textAlign='center' mb={8}>
          <Typography
            variant='h1'
            color='textSecondary'
            className={classes.fontSize}
          >
            Frequently asked Questions
          </Typography>
        </Box>
        <Grid container justify='space-between'>
          <Grid item xs={12} sm={5}>
            <Box display='flex' flexDirection='column' mb={8}>
              <Typography
                variant='body1'
                style={{
                  color: theme.palette.green.main,
                  marginBottom: theme.spacing(1),
                }}
              >
                What is the OneRare Metaverse ?
              </Typography>
              <Typography variant='body1' color='textSecondary'>
                OneRare is the ultimate Food Metaverse comprising of four areas:
              </Typography>
              <Typography variant='body1' color='textSecondary'>
                The Farm - where Farmers grow Ingredient crops
              </Typography>
              <Typography variant='body1' color='textSecondary'>
                Farmer’s Market - Marketplace for Farmers & Collectors
              </Typography>
              <Typography variant='body1' color='textSecondary'>
                Kitchen - The Dish hub with Recipes to mint Dish NFTs
              </Typography>
              <Typography variant='body1' color='textSecondary'>
                Playground - NFT Owners battle in Mini Games
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Box display='flex' flexDirection='column' mb={8}>
              <Typography
                variant='body1'
                style={{
                  color: theme.palette.cyan.main,
                  marginBottom: theme.spacing(1),
                }}
              >
                How do I make 💰 with One Rare ?
              </Typography>
              <Typography variant='body1' color='textSecondary'>
                In our Play-to-earn foodverse, there are many opportunities to
                earn. Farmers can grow Ingredients & sell them at the Farmer’s
                Market to Collectors. Collectors can mint Dish NFTs & play games
                at our playground for special rewards.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Box display='flex' flexDirection='column' mb={8}>
              <Typography
                variant='body1'
                style={{
                  color: theme.palette.pink.main,
                  marginBottom: theme.spacing(1),
                }}
              >
                How do I make Dishes?
              </Typography>
              <Typography variant='body1' color='textSecondary'>
                Each Dish has a Recipe akin to real life. Players can claim
                unique Dish NFTs by following the recipe & collecting
                Ingredients from Farm & Farmer's Market. Dishes can be Rare (on
                a curve) or Rarest of All (unique), so you definitely want the
                first shovel in !
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Box display='flex' flexDirection='column' mb={8}>
              <Typography
                variant='body1'
                style={{
                  color: theme.palette.green.main,
                  marginBottom: theme.spacing(1),
                }}
              >
                What will be on the OneRare Menu ?
              </Typography>
              <Typography variant='body1' color='textSecondary'>
                Exciting foods galore ! OneRare features signature Dishes from
                Celebrity Chefs, Iconic Restaurants, and Food Brands. We also
                have a special Food menu for Web3 projects & game characters.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FAQSection;
