import React from 'react';
import {
  Box,
  makeStyles,
  Button,
  Typography,
  useMediaQuery,
  OutlinedInput,
  Divider,
  InputAdornment,
} from '@material-ui/core';
import { theme } from '../../App';
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch } from 'react-redux';
import { SORT_CRITERIA } from '../../config';

const useStyles = makeStyles(() => ({
  root: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  inputMarginDense: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  inputRoot: {
    backgroundColor: '#6F6F6F',
    height: '100%',
    position: 'absolute',
    right: 0,
    color: 'white',
    padding: 1,
  },
}));

const IngredientsFilterSection = ({setSelectedSortCriteria,setSearchTerm}) => {
  const classes = useStyles();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const matches = useMediaQuery('(min-width:800px)');
  const matches2 = useMediaQuery('(min-width:1220px)');

	const sortIngredients=(sortCriteria)=>{
		setSelectedSortCriteria(sortCriteria)
	}

  return (
    <Box
      display='flex'
      flexDirection={!matches ? 'column' : 'row-reverse'}
      justifyContent='space-between'
      mt={!matches ? theme.spacing(0.5) : theme.spacing(0.8)}
      mb={!matches ? theme.spacing(0.5) : theme.spacing(1.3)}
    >
      <Box
        display={!matches && 'flex'}
        justifyContent={!matches && 'center'}
        mb={!matches && theme.spacing(0.5)}
      >
        <OutlinedInput
          margin='dense'
          placeholder='SEARCH'
          onChange={(event)=>setSearchTerm(event.target.value)}
          classes={{
            root: classes.root,
            inputMarginDense: classes.inputMarginDense,
          }}
          endAdornment={
            <InputAdornment
              position='end'
              classes={{ root: classes.inputRoot }}
            >
              <SearchIcon />
            </InputAdornment>
          }
        />
      </Box>
      <Box
        // width={xs ? 4 / 4 : 3 / 4}
        display='flex'
        alignItems={matches2 && 'center'}
        flexWrap='wrap'
        flex={1}
      >
        <Box>
          <Typography style={{ marginRight: theme.spacing(2) }}>
            SORT BY:
          </Typography>
        </Box>
        <Box display='flex' flex={1} flexWrap='wrap'>
          <Button
            style={{
              padding: 0,
              minWidth: 'unset',
              marginRight: theme.spacing(1),
            }}
          >
            <Typography>Popular</Typography>
          </Button>
          <Divider
            orientation='vertical'
            style={{
              backgroundColor: 'black',
              height: '20px',
              width: '2px',
              alignSelf: 'center',
              marginRight: theme.spacing(1),
            }}
          />
          <Button
            style={{
              padding: 0,
              minWidth: 'unset',
              marginRight: theme.spacing(1),
            }}
						onClick={()=>sortIngredients(SORT_CRITERIA.Latest)}
          >
            <Typography>Latest</Typography>
          </Button>
          <Divider
            orientation='vertical'
            style={{
              backgroundColor: 'black',
              height: '20px',
              width: '2px',
              alignSelf: 'center',
              marginRight: theme.spacing(1),
            }}
          />
          <Button
            style={{
              padding: 0,
              minWidth: 'unset',
              marginRight: theme.spacing(1),
            }}
						onClick={()=>sortIngredients(SORT_CRITERIA.Oldest)}
          >
            <Typography>OLDEST</Typography>
          </Button>
          <Divider
            orientation='vertical'
            style={{
              backgroundColor: 'black',
              height: '20px',
              width: '2px',
              alignSelf: 'center',
              marginRight: theme.spacing(1),
            }}
          />
          <Button
            style={{
              padding: 0,
              minWidth: 'unset',
              marginRight: theme.spacing(1),
            }}
						onClick={()=>sortIngredients(SORT_CRITERIA.AToZ)}
          >
            <Typography>A TO Z</Typography>
          </Button>
          <Divider
            orientation='vertical'
            style={{
              backgroundColor: 'black',
              height: '20px',
              width: '2px',
              alignSelf: 'center',
              marginRight: theme.spacing(1),
            }}
          />
          <Button
            style={{
              padding: 0,
              minWidth: 'unset',
              marginRight: theme.spacing(1),
            }}
						onClick={()=>sortIngredients(SORT_CRITERIA.ZToA)}
          >
            <Typography>Z TO A</Typography>
          </Button>
          <Divider
            orientation='vertical'
            style={{
              backgroundColor: 'black',
              height: '20px',
              width: '2px',
              alignSelf: 'center',
              marginRight: theme.spacing(1),
            }}
          />
          <Button
            style={{
              padding: 0,
              minWidth: 'unset',
              marginRight: theme.spacing(1),
            }}
						onClick={()=>sortIngredients(SORT_CRITERIA.LowestPrice)}
          >
            <Typography>LOWEST PRICE</Typography>
          </Button>
          <Divider
            orientation='vertical'
            style={{
              backgroundColor: 'black',
              height: '20px',
              width: '2px',
              alignSelf: 'center',
              marginRight: theme.spacing(1),
            }}
          />
          <Button
            style={{
              padding: 0,
              minWidth: 'unset',
              marginRight: theme.spacing(1),
            }}
						onClick={()=>sortIngredients(SORT_CRITERIA.HighestPrice)}
          >
            <Typography>HIGHEST PRICE</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default IngredientsFilterSection;
