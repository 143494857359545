import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Dialog,
  useMediaQuery,
  Typography,
  Hidden,
  Button,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { setError, setNetworkModalOpen } from '../../reducers/UiReducer';
import { theme } from '../../App';
import JuiceCornflakes from '../../assets/images/JuiceCornflakes.svg';
import SuccessfulOrder from '../../assets/images/SuccessfulOrder.svg';
import OrderError from '../../assets/images/OrderError.svg';
import CancelIcon from '../../assets/images/CancelIcon.svg';
import { useHistory, useParams } from 'react-router-dom';
import ethersServiceProvider from '../../services/ethersServiceProvider';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const NetworkErrorModal = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const { networkErrorModalOpen, error } = store.ui;
  const history = useHistory();

  const handleClose = () => {
    dispatch(setNetworkModalOpen());
  };

  const switchToKovanNetwork = async () => {
    await ethersServiceProvider.switchEthereumChain();
    handleClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={networkErrorModalOpen}
      maxWidth='md'
    >
      <Box
        border='10px solid #D1FF1A'
        px={!sm ? '5vw' : '5vw'}
        py={!sm ? '10vh' : '5vh'}
      >
        <Box
          style={{ position: 'absolute', right: '3%', top: '5%' }}
          onClick={handleClose}
        >
          <img alt='cancel icon' src={CancelIcon} />
        </Box>
        <Grid container>
          <Hidden xsDown>
            <Grid item xs={12} sm={3} style={{ textAlign: 'center' }}>
              <object
                type='image/svg+xml'
                data={JuiceCornflakes}
                style={{
                  marginBottom: theme.spacing(3),
                  height: 200,
                }}
              >
                <img
                  src={JuiceCornflakes}
                  alt='juice and cornflakes'
                  style={{ height: 250, width: '100%', objectFit: 'contain' }}
                />
              </object>
            </Grid>
          </Hidden>
          <Grid
            item
            xs={12}
            sm={9}
            style={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '2vh',
              paddingBottom: '2vh',
            }}
          >
            <Typography variant='h2' style={{ marginBottom: theme.spacing(2) }}>
              Cornflakes with Juice ? Yuck.
            </Typography>
            <Hidden smUp>
              <object
                type='image/svg+xml'
                data={JuiceCornflakes}
                style={{
                  marginBottom: theme.spacing(3),
                  height: 200,
                }}
              >
                <img
                  src={JuiceCornflakes}
                  alt='juice and cornflakes'
                  style={{
                    height: 200,
                    marginBottom: theme.spacing(5),
                    objectFit: 'contain',
                  }}
                />
              </object>
            </Hidden>
            <Typography
              variant='body1'
              style={{ marginBottom: theme.spacing(5) }}
            >
              YOU ARE CURRENTLY CONNECTED TO AN UNSUPPORTED NETWORK.
              <br />
              SWITCH TO KOVAN TEST NETWORK TO PROCEED.
            </Typography>
            <Button
              style={{
                borderRadius: 12,
                paddingLeft: theme.spacing(5),
                paddingRight: theme.spacing(5),
                border: '1px solid black',
              }}
              fullWidth={sm && true}
              variant='outlined'
              color='secondary'
              onClick={switchToKovanNetwork}
            >
              SWITCH TO KOVAN
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default NetworkErrorModal;
