import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Typography,
  useMediaQuery,
  Button,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { theme } from '../../App';
import gameService from '../../services/gameService';
import {
  addToPurchasedRecipe,
  setConfirmTransactionModal,
  setError,
  setLoading,
  setModalOpen,
} from '../../reducers/UiReducer';
import CookerIcon from '../../assets/images/CookerIcon';
import Lottie from 'react-lottie';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const CombineIngredientsSection = ({ selectedRecipe }) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const { purchasedIngredient } = store.ui;
  let allCollected = true;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: CookerIcon,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  selectedRecipe.ingredients &&
    selectedRecipe.ingredients.forEach((ingr) => {
      allCollected =
        allCollected &
        (purchasedIngredient.filter((ing) => ing.tokenId == ingr.tokenId)
          .length >
          0);
    });

  const buyRecipe = async () => {
    try {
      const data = selectedRecipe.ingredients.map((ingr) => ingr._id).join('');
      dispatch(setConfirmTransactionModal());
      const cookTx = await gameService.cookDish(
        selectedRecipe.tokenId,
        `0x${data}`
      );
      dispatch(setConfirmTransactionModal());
      dispatch(setLoading(true));
      await cookTx.wait();
      dispatch(addToPurchasedRecipe(selectedRecipe));
    } catch (err) {
      dispatch(setConfirmTransactionModal());
      dispatch(setError(true));
    }
    dispatch(setModalOpen());
    dispatch(setLoading(false));
  };

  return (
    <Box my={theme.spacing(2)} bgcolor='secondary.main'>
      <Box
        py={theme.spacing(1)}
        px={sm ? theme.spacing(0.5) : theme.spacing(4)}
        textAlign='center'
        display='flex'
        flexDirection='column'
        alignItems='center'
      >
        <Typography variant='h4' color='textSecondary'>
          COLLECTED ALL INGREDIENTS ?<br /> COMBINE THEM TO COOK UP YOUR DISH
        </Typography>
        <Box
          height={200}
          py={theme.spacing(!xs ? 0.5 : 0.7)}
          pr={!xs && theme.spacing(0)}
          pl={!xs && theme.spacing(1)}
        >
          <Lottie options={defaultOptions} />
        </Box>
        <Button
          variant='contained'
          style={{
            borderRadius: 12,
            backgroundColor: theme.palette.primary.main,
            color: 'black',
          }}
          fullWidth={xs && true}
          onClick={buyRecipe}
          disabled={!allCollected}
        >
          <Typography variant='h4'>Combine</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default CombineIngredientsSection;
