import React, { useEffect, useMemo, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import RecipesIntroSection from '../components/recipesComponents/RecipesIntroSection';
import RecipesFilterSection from '../components/recipesComponents/RecipesFilterSection';
import RecipesItemsSection from '../components/recipesComponents/RecipesItemsSection';
import {
  getRecipesDispatch,
  searchDishesThunk,
  sortRecipeThunk,
} from '../reducers/UiReducer';
import { debounce } from 'lodash';

const useStyles = makeStyles((theme) => ({}));

const RecipesPage = () => {
  const store = useSelector((state) => state);
  const [searchTerm, setSearchTerm] = useState(null);
  const [currPageNum, setCurrPageNum] = useState(1);
  const [selectedSortCriteria, setSelectedSortCriteria] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles();
  const { recipesList } = store.ui;

  const debouncedSearch = useMemo(
    () =>
      debounce(
        (_searchTerm, _pageNumber) =>
          dispatch(
            searchDishesThunk({
              searchTerm: _searchTerm,
              pageNumber: _pageNumber,
            })
          ),
        200
      ),
    [searchTerm, currPageNum]
  );

  useEffect(() => {
    if (selectedSortCriteria)
      dispatch(
        sortRecipeThunk({ sortCriteria: selectedSortCriteria, pageNumber: 1 })
      );
    else dispatch(getRecipesDispatch(1));
    setCurrPageNum(1);
  }, [selectedSortCriteria]);

  useEffect(() => {
    if (searchTerm !== null) {
      if (searchTerm) debouncedSearch(searchTerm, 1);
      else dispatch(getRecipesDispatch(1));
      setCurrPageNum(1);
      return () => debouncedSearch.cancel();
    }
  }, [searchTerm]);

  const handleLoadMore = () => {
    if (searchTerm) {
      debouncedSearch(searchTerm, currPageNum + 1);
    } else if (selectedSortCriteria) {
      dispatch(
        sortRecipeThunk({
          sortCriteria: selectedSortCriteria,
          pageNumber: currPageNum + 1,
        })
      );
    } else {
      dispatch(getRecipesDispatch(currPageNum + 1));
    }
    setCurrPageNum(currPageNum + 1);
  };

  return (
    <Box px='7vw' py='5vh'>
      <RecipesIntroSection />
      <RecipesFilterSection
        setSearchTerm={setSearchTerm}
        setSelectedSortCriteria={setSelectedSortCriteria}
      />
      <InfiniteScroll
        next={handleLoadMore}
        hasMore={recipesList.hasMore}
        dataLength={recipesList.length || 0}
        style={{ overflow: 'scroll', overflowY: 'hidden' }}
      >
        <RecipesItemsSection recipesList={recipesList.list} />
      </InfiniteScroll>
    </Box>
  );
};

export default RecipesPage;
