import React, { useState } from 'react';
import {
  Box,
  makeStyles,
  Button,
  Typography,
  useMediaQuery,
  OutlinedInput,
  Divider,
  Checkbox,
  InputAdornment,
  Switch,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { theme } from '../../App';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { SORT_CRITERIA } from '../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  inputMarginDense: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  inputRoot: {
    backgroundColor: '#6F6F6F',
    height: '100%',
    position: 'absolute',
    right: 0,
    color: 'white',
    padding: 1,
  },
  buttonRoot: {
    borderRadius: 0,
  },
}));

const RecipesFilterSection = ({ setSearchTerm,setSelectedSortCriteria }) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const matches = useMediaQuery('(min-width:800px)');
  const matches2 = useMediaQuery('(min-width:1220px)');

  const [anchorEl, setAnchorEl] = useState(null);
  const [buttonText, setButtonText] = useState('CUISINE');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    event.currentTarget.textContent.length > 0 &&
      setButtonText(event.currentTarget.textContent);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

	const sortRecipe=(sortCriteria)=>{
		setSelectedSortCriteria(sortCriteria)
	}

  return (
    <Box
      display='flex'
      flexDirection={!matches ? 'column' : 'row-reverse'}
      justifyContent='space-between'
      mt={!matches ? theme.spacing(0.5) : theme.spacing(0.8)}
      mb={!matches ? theme.spacing(0.5) : theme.spacing(1.3)}
    >
      <Box
        display={!matches && 'flex'}
        justifyContent={!matches && 'center'}
        mb={!matches && theme.spacing(0.5)}
      >
        <OutlinedInput
          margin='dense'
          placeholder='SEARCH'
          onChange={(event) => setSearchTerm(event.target.value)}
          classes={{
            root: classes.root,
            inputMarginDense: classes.inputMarginDense,
          }}
          endAdornment={
            <InputAdornment
              position='end'
              classes={{ root: classes.inputRoot }}
            >
              <SearchIcon />
            </InputAdornment>
          }
        />
      </Box>
      <Box display='flex' flexDirection='column'>
        <Box
          display='flex'
          alignItems={matches2 && 'center'}
          flexWrap='wrap'
          flex={1}
        >
          <Box>
            <Typography style={{ marginRight: theme.spacing(2) }}>
              SORT BY:
            </Typography>
          </Box>
          <Box display='flex' flex={1} flexWrap='wrap'>
            <Button
              style={{
                padding: 0,
                minWidth: 'unset',
                marginRight: theme.spacing(1),
              }}
            >
              <Typography>Popular</Typography>
            </Button>
            <Divider
              orientation='vertical'
              style={{
                backgroundColor: 'black',
                height: '20px',
                width: '2px',
                alignSelf: 'center',
                marginRight: theme.spacing(1),
              }}
            />
            <Button
              style={{
                padding: 0,
                minWidth: 'unset',
                marginRight: theme.spacing(1),
              }}
							onClick={()=>sortRecipe(SORT_CRITERIA.Latest)}
            >
              <Typography>Latest</Typography>
            </Button>
            <Divider
              orientation='vertical'
              style={{
                backgroundColor: 'black',
                height: '20px',
                width: '2px',
                alignSelf: 'center',
                marginRight: theme.spacing(1),
              }}
            />
            <Button
              style={{
                padding: 0,
                minWidth: 'unset',
                marginRight: theme.spacing(1),
              }}
							onClick={()=>sortRecipe(SORT_CRITERIA.Oldest)}
            >
              <Typography>OLDEST</Typography>
            </Button>
            <Divider
              orientation='vertical'
              style={{
                backgroundColor: 'black',
                height: '20px',
                width: '2px',
                alignSelf: 'center',
                marginRight: theme.spacing(1),
              }}
            />
            <Button
              style={{
                padding: 0,
                minWidth: 'unset',
                marginRight: theme.spacing(1),
              }}
							onClick={()=>sortRecipe(SORT_CRITERIA.AToZ)}
            >
              <Typography>A TO Z</Typography>
            </Button>
            <Divider
              orientation='vertical'
              style={{
                backgroundColor: 'black',
                height: '20px',
                width: '2px',
                alignSelf: 'center',
                marginRight: theme.spacing(1),
              }}
            />
            <Button
              style={{
                padding: 0,
                minWidth: 'unset',
                marginRight: theme.spacing(1),
              }}
							onClick={()=>sortRecipe(SORT_CRITERIA.ZToA)}
            >
              <Typography>Z TO A</Typography>
            </Button>
          </Box>
        </Box>
        <Box
          display='flex'
          alignItems={matches2 && 'center'}
          flexWrap='wrap'
          flex={1}
        >
          <Box>
            <Typography style={{ marginRight: theme.spacing(2) }}>
              FILTERS:
            </Typography>
          </Box>
          <Box display='flex' flex={1} alignItems='center'>
            <Switch
              checked={checked}
              onChange={handleChange}
              color='primary'
              name='checked'
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <Typography style={{ marginLeft: theme.spacing(1) }}>
              ONLY SHOW AVAILABLE DISHES
            </Typography>
          </Box>
          <Button
            variant='outlined'
            size='small'
            style={{ marginLeft: theme.spacing(2) }}
            classes={{ root: classes.buttonRoot }}
            aria-controls='simple-menu'
            aria-haspopup='true'
            onClick={handleClick}
          >
            {buttonText}
            <ArrowDownwardIcon
              fontSize='inherit'
              style={{ marginLeft: theme.spacing(4) }}
            />
          </Button>
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>American</MenuItem>
            <MenuItem onClick={handleClose}>French</MenuItem>
            <MenuItem onClick={handleClose}>Mexican</MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

export default RecipesFilterSection;
