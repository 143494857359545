import React, { useState } from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Typography,
  Divider,
  Tooltip,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import CustomButton from '../resuableComponents/CustomButton';
import { theme } from '../../App';
import { useHistory } from 'react-router-dom';
import stakingService from '../../services/stakingService';
import {
  addToPurchasedRecipe,
  addToStakedTokens,
  removeFromPurchasedRecipe,
  removeFromStakedTokens,
  setConfirmTransactionModal,
  setError,
  setLoading,
} from '../../reducers/UiReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'black',
    height: 2,
    width: 180,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'black',
  },
}));

const StakeItem = ({ item, index, staked }) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const stakeToken = async () => {
    try {
			dispatch(setConfirmTransactionModal());
      const stakeTx=await stakingService.stakeTokens(item.tokenId, 1);
			dispatch(setConfirmTransactionModal());
			dispatch(setLoading(true));
			await stakeTx.wait();
      dispatch(removeFromPurchasedRecipe(item));
      dispatch(addToStakedTokens(item));
    } catch (err) {
			dispatch(setConfirmTransactionModal());
      dispatch(setError(true));
    }
    dispatch(setLoading(false));
  };

  const unstakeToken = async () => {
    try {
			dispatch(setConfirmTransactionModal());
      const unstakeTx=await stakingService.unstakeTokens(item.tokenId, 1);
			dispatch(setConfirmTransactionModal());
			dispatch(setLoading(true));
			await unstakeTx.wait();
      dispatch(addToPurchasedRecipe(item));
      dispatch(removeFromStakedTokens(item));
    } catch (err) {
			dispatch(setConfirmTransactionModal());
      dispatch(setError(true));
    }
    dispatch(setLoading(false));
  };

  const handleClick = async () => {
    if (staked) {
      unstakeToken();
    } else {
      stakeToken();
    }
  };

  return (
    <Box
      textAlign="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <object type="image/svg+xml" data={item.image}>
        <img src={item.image} alt="ingredient icon" />
      </object>
      <Tooltip
        title={item.name}
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <Typography variant="h4" style={{ paddingTop: theme.spacing(3) }}>
          {item.name.toUpperCase()}
        </Typography>
      </Tooltip>
      <CustomButton
        style={{
          marginBottom: theme.spacing(3),
          marginTop: theme.spacing(1.5),
        }}
        onClick={handleClick}
        staked={staked}
      >
        {staked ? 'UNSTAKE' : 'STAKE'}
      </CustomButton>
    </Box>
  );
};

export default StakeItem;
