import React from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import SplashImage from '../assets/images/SplashImage.svg';
import lottieData from '../assets/images/lottieData';
import FAQSection from '../components/homeComponents/FAQSection';
import IntroductorySection from '../components/homeComponents/IntroductorySection';
import Lottie from 'react-lottie';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const HomePage = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Box>
      <Lottie options={defaultOptions} height={'100%'} width={'100%'} />
      <IntroductorySection />
      <FAQSection />
    </Box>
  );
};

export default HomePage;
