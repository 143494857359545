import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Dialog,
  useMediaQuery,
  Typography,
  Hidden,
  Button,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { setError, setConfirmTransactionModal } from '../../reducers/UiReducer';
import { theme } from '../../App';
import SuccessfulOrder from '../../assets/images/SuccessfulOrder.svg';
import OrderError from '../../assets/images/OrderError.svg';
import CancelIcon from '../../assets/images/CancelIcon.svg';
import { useHistory, useParams } from 'react-router-dom';
import Whisk from '../../assets/images/Whisk';
import Lottie from 'react-lottie';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const ConfirmTransactionModal = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const { confirmTransactionModal, error } = store.ui;
  const history = useHistory();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Whisk,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handleClose = () => {
    dispatch(setConfirmTransactionModal());
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={confirmTransactionModal}
      maxWidth='md'
    >
      <Box
        border='10px solid #D1FF1A'
        px={!sm ? '5vw' : '5vw'}
        py={!sm ? '10vh' : '5vh'}
      >
        <Box
          style={{ position: 'absolute', right: '3%', top: '5%' }}
          onClick={handleClose}
        >
          <img alt='cancel icon' src={CancelIcon} />
        </Box>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={9}
            style={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '2vh',
              paddingBottom: '2vh',
            }}
          >
            <Typography variant='h2' style={{ marginBottom: theme.spacing(2) }}>
              Whisk Whisk Whisk !
            </Typography>
            <Hidden smUp>
              <Lottie options={defaultOptions} />
            </Hidden>
            <Typography variant='body1'>
              CONFIRM THIS TRANSACTION
              <br />
              WITH YOUR WALLET TO CONTINUE
            </Typography>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={12} sm={3} style={{ textAlign: 'center' }}>
              <Lottie options={defaultOptions} />
            </Grid>
          </Hidden>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default ConfirmTransactionModal;
