import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import HomePage from '../pages/HomePage';
import Layout from './resuableComponents/Layout';
import IngredientsPage from '../pages/IngredientsPage';
import IngredientSelectedPage from '../pages/IngredientSelectedPage';
import RecipesPage from '../pages/RecipesPage';
import RecipeSelectedPage from '../pages/RecipeSelectedPage';
import StakePage from '../pages/StakePage';
import RewardsPage from '../pages/RewardsPage';
import AdminPage from '../pages/AdminPage';
import Error404Page from '../pages/Error404Page';
import PrelaunchHomepage from '../pages/PrelaunchHomepage';
import CustomPurchaseModal from './resuableComponents/CustomPurchaseModal';
import { getTokensOwned } from '../reducers/UiReducer';
import { useMetamask } from './hooks/useMetamask';
import NetworkErrorModal from './resuableComponents/NetworkErrorModal';
import ConfirmTransactionModal from './resuableComponents/ConfirmTransactionModal';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const MainRouter = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { active, tried } = useMetamask();

  useEffect(() => {
    if (active && tried) {
      dispatch(getTokensOwned());
    }
  }, [active, tried]);

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path='/admin'>
          <Layout>
            <AdminPage />
          </Layout>
        </Route>
        <Route exact path='/connect'>
          <Layout>
            <HomePage />
          </Layout>
        </Route>
        <Route exact path='/rewards'>
          <Layout>
            <RewardsPage />
          </Layout>
        </Route>
        <Route exact path='/stake'>
          <Layout>
            <StakePage />
          </Layout>
        </Route>
        <Route exact path='/recipes'>
          <Layout>
            <RecipesPage />
          </Layout>
        </Route>
        <Route exact path='/recipes/:id'>
          <Layout>
            <RecipeSelectedPage />
          </Layout>
        </Route>
        <Route exact path='/ingredients'>
          <Layout>
            <IngredientsPage />
          </Layout>
        </Route>
        <Route exact path='/ingredients/:id'>
          <Layout>
            <IngredientSelectedPage />
          </Layout>
        </Route>
        <Route exact path='/'>
          <Layout noContainer>
            {/* <PrelaunchHomepage /> */}
            <HomePage />
          </Layout>
        </Route>
        <Route path='*'>
          <Layout>
            <Error404Page />
          </Layout>
        </Route>
      </Switch>
      <CustomPurchaseModal />
      <NetworkErrorModal />
      <ConfirmTransactionModal />
    </Router>
  );
};

export default MainRouter;
