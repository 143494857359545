import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { axiosInstance } from '../Axios';
import ethersServiceProvider from '../services/ethersServiceProvider';
import stakingService from '../services/stakingService';

const initialState = {
  navItems: ['INGREDIENTS', 'RECIPES', 'STAKE', 'REWARDS'],
  headerMenuExpanded: false,
  selectedIngredientTab: 'All Ingredients',
  loading: false,
  error: null,
  ingredientsList: {list:[],hasMore:false},
  recipesList: {list:[],hasMore:false},
  recipe: [],
  selectedReward: 'Ingredients',
  rewardsList: [],
  selectedDashboard: 'Ingredients',
  modalOpen: false,
  temp: [],
  selectedIngredient: {},
  selectedRecipe: {},
  recipeIngredients: [],
  purchasedIngredient: [],
  purchasedRecipe: [],
  stakedTokens: null,
  rewardsBalance: '',
  networkErrorModalOpen: false,
  confirmTransactionModal: false,
};

export const getIngredientById = createAsyncThunk(
  'getIngredientById',
  async (id) => {
    const { data } = await axiosInstance.get(`/api/ingredient/${id}`);
    return data;
  }
);
export const getRecipieById = createAsyncThunk('getRecipieById', async (id) => {
  const { data } = await axiosInstance.get(`/api/dish/${id}`);
  return data;
});

export const sortIngredientsThunk = createAsyncThunk(
  'sortIngredients',
  async (sortData) => {
		const offset = (sortData.pageNumber-1)*10;
    const { data } = await axiosInstance.get(
      `/api/ingredients?sort=${sortData.sortCriteria}&offset=${offset}`,
    );
    return data;
  },
);

export const sortRecipeThunk = createAsyncThunk(
  'sortRecipe',
  async (sortData) => {
		const offset = (sortData.pageNumber-1)*10;
    const { data } = await axiosInstance.get(
      `/api/dishes?sort=${sortData.sortCriteria}&offset=${offset}`,
    );
    return data;
  }
);

export const searchIngredientsThunk = createAsyncThunk(
  'searchIngredients',
  async (searchData) => {
		const offset = (searchData.pageNumber-1)*10;
    const { data } = await axiosInstance.get(
      `/api/ingredients?search=${searchData.searchTerm}&offset=${offset}`,
    );
    return data;
  }
);

export const searchDishesThunk = createAsyncThunk(
  'searchDishesThunk',
  async (searchData) => {
		const offset = (searchData.pageNumber-1)*10;
    const { data } = await axiosInstance.get(
      `/api/dishes?search=${searchData.searchTerm}&offset=${offset}`,
    );
    return data;
  }
);

export const getTokensOwned = createAsyncThunk('getTokensOwned', async () => {
  const data = await ethersServiceProvider.getAllTokensOwned();
  const res = await Promise.all(
    data.map(async (id) => {
      const type = await ethersServiceProvider.getTokenType(id);
      if (type == 1 || type == 2) {
        return axiosInstance.get(`/api/ingredient/${id}`);
      } else {
        return axiosInstance.get(`/api/dish/${id}`);
      }
    })
  );
  return res.map(({ data }) => data);
});

export const getStakedTokens = createAsyncThunk('getStakedTokens', async () => {
  const data = await stakingService.getStakedTokens();
  const stakedTokens = await Promise.all(
    data.map((tokenId) => axiosInstance.get(`/api/dish/${tokenId}`))
  );
  return stakedTokens.map(({ data }) => data);
});

export const getRewardsBalance = createAsyncThunk(
  'getRewardsBalance',
  async () => {
    const data = await stakingService.getRewardsBalance();
    return data;
  }
);

// export const getRecipieIngredients=createAsyncThunk(
//   'getRecipieIngredients',
//   async (id) => {
// 		const ingredientList=await Promise.all(id.map(async (_id)=>{
// 			const {data} = await axiosInstance.get(`/api/ingredient/${_id}`);
// 			return data;
// 		}));
// 		return ingredientList;
//   },
// );

export const getIngredientsDispatch = (pageNumber) => async (dispatch) => {
  try {
		if(pageNumber==1){
			dispatch(setLoading(true));
		}
		const offset=(parseInt(pageNumber,10)-1)*10;
    const { data } = await axiosInstance.get(`/api/ingredients?offset=${offset}`);
    dispatch(getIngredientsSuccess(data));
  } catch (error) {
    dispatch(
      getIngredientsFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};

export const getRecipesDispatch = (pageNumber) => async (dispatch) => {
  try {
		if(pageNumber==1){
			dispatch(setLoading(true));
		}
		const offset=(parseInt(pageNumber,10)-1)*10;
    const { data } = await axiosInstance.get(`/api/dishes?offset=${offset}`);
    dispatch(getRecipesSuccess(data));
  } catch (error) {
    dispatch(
      getRecipesFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};

const UiReducer = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleHeaderMenuExpanded(state) {
      state.headerMenuExpanded = !state.headerMenuExpanded;
    },
    selectIngredientTab(state, action) {
      state.selectedIngredientTab = action.payload;
    },
    selectReward(state, action) {
      state.selectedReward = action.payload;
    },
    selectDashboard(state, action) {
      state.selectedDashboard = action.payload;
    },
    setModalOpen(state) {
      state.modalOpen = !state.modalOpen;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    getIngredientsSuccess(state, action) {
      state.loading = false;
			if(action.payload.page!==1){
				state.ingredientsList.list = [...state.ingredientsList.list,...action.payload.docs];
			}else{
				state.ingredientsList.list = [...action.payload.docs];
			}
			state.ingredientsList.hasMore = action.payload.hasNextPage;
    },
    getIngredientsFail(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    getRecipesSuccess(state, action) {
      state.loading = false;
			if(action.payload.page!==1){
				state.recipesList.list = [...state.recipesList.list,...action.payload.docs];
			}else{
				state.recipesList.list = [...action.payload.docs];
			}
			state.recipesList.hasMore = action.payload.hasNextPage;
    },
    getRecipesFail(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    getSelectedIngredientFail(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
		clearSelectedIngredient(state,action){
			state.selectedIngredient = {};
		},
    addToPurchasedIngredients(state, action) {
      state.purchasedIngredient.push(action.payload);
    },
    addToPurchasedRecipe(state, action) {
      state.purchasedRecipe.push(action.payload);
    },
    setNetworkModalOpen(state, action) {
      state.networkErrorModalOpen = !state.networkErrorModalOpen;
    },
    setConfirmTransactionModal(state, action) {
      state.confirmTransactionModal = !state.confirmTransactionModal;
    },
    removeFromPurchasedIngredients(state, action) {
      state.purchasedIngredient = state.purchasedIngredient.filter(
        (item) =>
          action.payload[0].filter((ingr) => ingr.tokenId == item.tokenId)
            .length == 0
      );
    },
    removeFromPurchasedRecipe(state, action) {
      state.purchasedRecipe = state.purchasedRecipe.filter(
        (item) => item.tokenId != action.payload.tokenId
      );
    },
    addToStakedTokens(state, action) {
      state.stakedTokens = [...state.stakedTokens, action.payload];
    },
    removeFromStakedTokens(state, action) {
      state.stakedTokens = state.stakedTokens.filter(
        (item) => item.tokenId != action.payload.tokenId
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIngredientById.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getIngredientById.fulfilled, (state, action) => {
      state.selectedIngredient = action.payload;
			state.loading = false;
			state.error = false;
    });
    builder.addCase(getIngredientById.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getRecipieById.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getRecipieById.fulfilled, (state, action) => {
      state.loading = false;
      state.selectedRecipe = action.payload;
    });
    builder.addCase(getRecipieById.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getTokensOwned.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(sortIngredientsThunk.pending, (state,action) => {
			if(action.meta.arg.pageNumber===1){
				state.loading = true;
			}
      state.error = false;
    });
    builder.addCase(sortIngredientsThunk.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(sortIngredientsThunk.fulfilled, (state, action) => {
			if(action.payload.page!==1){
				state.ingredientsList.list = [...state.ingredientsList.list,...action.payload.docs];
			}else{
				state.ingredientsList.list = action.payload.docs;
			}
			state.ingredientsList.hasMore = action.payload.hasNextPage;
      state.loading = false;
    });
		builder.addCase(sortRecipeThunk.pending, (state,action) => {
			if(action.meta.arg.pageNumber===1){
				state.loading = true;
			}
      state.error = false;
    });
    builder.addCase(sortRecipeThunk.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(sortRecipeThunk.fulfilled, (state, action) => {
			if(action.payload.page!==1){
				state.recipesList.list = [...state.recipesList.list,...action.payload.docs];
			}else{
				state.recipesList.list = action.payload.docs;
			}
			state.recipesList.hasMore = action.payload.hasNextPage;
      state.loading = false;
    });
    builder.addCase(searchIngredientsThunk.pending, (state,action) => {
			if(action.meta.arg.pageNumber===1){
				state.loading = true;
			}
      state.error = false;
    });
    builder.addCase(searchIngredientsThunk.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(searchIngredientsThunk.fulfilled, (state, action) => {
			if(action.payload.page!==1){
				state.ingredientsList.list = [...state.ingredientsList.list,...action.payload.docs];
			}else{
				state.ingredientsList.list = action.payload.docs;
			}
			state.ingredientsList.hasMore = action.payload.hasNextPage;
      state.loading = false;
    });
    builder.addCase(searchDishesThunk.pending, (state,action) => {
			if(action.meta.arg.pageNumber===1){
				state.loading = true;
			}
      state.error = false;
    });
    builder.addCase(searchDishesThunk.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(searchDishesThunk.fulfilled, (state, action) => {
			if(action.payload.page!==1){
				state.recipesList.list = [...state.recipesList.list,...action.payload.docs];
			}else{
				state.recipesList.list = action.payload.docs;
			}
			state.recipesList.hasMore = action.payload.hasNextPage;
      state.loading = false;
    });
    builder.addCase(getTokensOwned.fulfilled, (state, action) => {
      state.loading = false;
      const purchasedIngredient = [];
      const purchasedRecipe = [];
      action.payload.map((ingr) => {
        if (ingr.type == 1 || ingr.type == 2) {
          purchasedIngredient.push(ingr);
        } else {
          purchasedRecipe.push(ingr);
        }
      });
      state.purchasedRecipe = purchasedRecipe;
      state.purchasedIngredient = purchasedIngredient;
    });
    builder.addCase(getStakedTokens.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getStakedTokens.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getStakedTokens.fulfilled, (state, action) => {
      state.loading = false;
      state.stakedTokens = action.payload;
    });
    builder.addCase(getRewardsBalance.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getRewardsBalance.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getRewardsBalance.fulfilled, (state, action) => {
      state.loading = false;
      state.rewardsBalance = action.payload;
    });
    // builder.addCase(getRecipieIngredients.fulfilled,(state,action)=>{
    // 	state.loading=false;
    // 	state.recepieIngredients=action.payload;
    // })
  },
});

const { actions } = UiReducer;

export const {
  toggleHeaderMenuExpanded,
  selectReward,
  selectDashboard,
  setModalOpen,
  setLoading,
  getIngredientsSuccess,
  getIngredientsFail,
  getRecipesSuccess,
  getRecipesFail,
  setSelectedIngredient,
  getSelectedIngredientFail,
  setError,
  addToPurchasedIngredients,
  addToPurchasedRecipe,
  selectIngredientTab,
  removeFromPurchasedIngredients,
  removeFromPurchasedRecipe,
  addToStakedTokens,
  removeFromStakedTokens,
  setNetworkModalOpen,
  setConfirmTransactionModal,
	clearSelectedIngredient
} = actions;

export default UiReducer;
