import React from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import StakeIntroSection from '../components/stakeComponents/StakeIntroSection';
import StakeItemsSection from '../components/stakeComponents/StakeItemsSection';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const StakePage = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Box px='7vw' py='5vh'>
      <StakeIntroSection />
      <StakeItemsSection />
    </Box>
  );
};

export default StakePage;
