import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Container,
  Typography,
  Hidden,
  useMediaQuery,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import SocialMediaItems from '../footerComponents/SocialMediaItems';
import DiscordIcon from '../../assets/images/DiscordIcon.svg';
import TelegramLogo from '../../assets/images/TelegramLogo.svg';
import TwitterLogo from '../../assets/images/TwitterLogo.svg';
import { theme } from '../../App';

const useStyles = makeStyles((theme) => ({
  flexDirection: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  linkItems: {
    fontWeight: 'bold',
    marginRight: '2rem',
    marginBottom: 8,
  },
}));

const Footer = ({ prelaunch }) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { navItems } = store.ui;
  const matches = useMediaQuery('(max-width:1150px)');

  return (
    <Box bgcolor='background.footerGrey'>
      <footer>
        <Container>
          <Box px='7vw' py='7vh'>
            {!prelaunch ? (
              <Box className={classes.flexDirection}>
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  textAlign='center'
                  alignItems='center'
                  mr='5vw'
                >
                  <Box>
                    <Typography variant='h2'>
                      What’s Cooking, {matches && <br />} Good Looking ?
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant='body2'
                      style={{
                        fontWeight: 'bold',
                        display: 'flex',
                        marginTop: 5,
                      }}
                    >
                      JOIN US ON DISCORD TO CATCH UP ON THE LATEST NEWS{' '}
                      <Hidden smDown>
                        <img
                          src={DiscordIcon}
                          alt='discord'
                          style={{ marginLeft: 5 }}
                          onClick={() =>
                            window.open('https://discord.gg/f2BTc2wzsw')
                          }
                        />
                      </Hidden>
                    </Typography>
                  </Box>
                </Box>

                <Box display='flex'>
                  <Hidden smDown>
                    <Box mr='5vw'>
                      <Link to='/' style={{ textDecoration: 'none' }}>
                        <Typography
                          variant='body2'
                          color='textPrimary'
                          className={classes.linkItems}
                        >
                          HOME
                        </Typography>
                      </Link>
                      <Link
                        to='/ingredients'
                        style={{ textDecoration: 'none' }}
                      >
                        <Typography
                          variant='body2'
                          color='textPrimary'
                          className={classes.linkItems}
                        >
                          FARMER’S MARKET
                        </Typography>
                      </Link>
                      <Link to='/recipes' style={{ textDecoration: 'none' }}>
                        <Typography
                          variant='body2'
                          color='textPrimary'
                          className={classes.linkItems}
                        >
                          KITCHEN
                        </Typography>
                      </Link>
                      <Link to='/stake' style={{ textDecoration: 'none' }}>
                        <Typography
                          variant='body2'
                          color='textPrimary'
                          className={classes.linkItems}
                        >
                          PLAYGROUND
                        </Typography>
                      </Link>
                      <Link to='/rewards' style={{ textDecoration: 'none' }}>
                        <Typography
                          variant='body2'
                          color='textPrimary'
                          className={classes.linkItems}
                        >
                          REWARDS
                        </Typography>
                      </Link>

                      {/* {navItems.map((item, index) => (
                        <Link
                          to={`/${item.toLowerCase()}`}
                          key={item}
                          style={{ textDecoration: 'none' }}
                        >
                          <Typography
                            variant='body2'
                            color='textPrimary'
                            className={classes.linkItems}
                          >
                            {item}
                          </Typography>
                        </Link>
                      ))} */}
                    </Box>
                  </Hidden>
                  <SocialMediaItems />
                </Box>
              </Box>
            ) : (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                textAlign='center'
              >
                <Box>
                  <Typography variant='h2'>
                    What’s Cooking, {matches && <br />} Good Looking ?
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                    JOIN US TO CATCH UP ON THE LATEST NEWS
                  </Typography>
                </Box>
                <Box display='flex' justifyContent='center' mt={4}>
                  <Box display='flex'>
                    <img
                      src={DiscordIcon}
                      alt='discord'
                      style={{
                        marginRight: theme.spacing(1),
                      }}
                      onClick={() =>
                        window.open('https://discord.gg/f2BTc2wzsw')
                      }
                    />
                    {!matches && (
                      <Typography
                        variant='body2'
                        style={{ marginRight: theme.spacing(5) }}
                      >
                        DISCORD
                      </Typography>
                    )}
                  </Box>
                  <Box display='flex'>
                    <img
                      src={TelegramLogo}
                      alt='telegram'
                      style={{
                        marginRight: theme.spacing(1),
                      }}
                      onClick={() =>
                        window.open('https://t.me/joinchat/pO88PtVrRQc3NWE9')
                      }
                    />
                    {!matches && (
                      <Typography
                        variant='body2'
                        style={{ marginRight: theme.spacing(5) }}
                      >
                        TELEGRAM
                      </Typography>
                    )}
                  </Box>
                  <Box display='flex'>
                    <img
                      src={TwitterLogo}
                      alt='twitter'
                      style={{
                        marginRight: theme.spacing(1),
                      }}
                      onClick={() =>
                        window.open('https://twitter.com/onerarenft')
                      }
                    />
                    {!matches && (
                      <Typography
                        variant='body2'
                        style={{ marginRight: theme.spacing(5) }}
                      >
                        TWITTER
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Container>
      </footer>
    </Box>
  );
};

export default Footer;
