import detectProvider from '@metamask/detect-provider';
import { ethers, FixedNumber } from 'ethers';

import ERC1155RG from "../abi/ERC1155RG.json";
import IERC20 from "../abi/IERC20.json";
import IERC1155Game from "../abi/ERC1155Game.json";
import StakingRewards from "../abi/StakingRewards.json";
import { SUPPORTED_CHAINS } from '../config';

class EthersServiceProvider {
  provider;
  currentAccount;
	erc1155RGContractInstance
	maticContractInstance;
	gameContractAddrInstance;
	stakingContractInstance;

  constructor() {}

  async getProvider() {
    const metaMaskprovider = await detectProvider();
    this.provider = new ethers.providers.Web3Provider(metaMaskprovider);
    return this.provider;
  }

  setCurrentAccount(address) {
    this.currentAccount = address;
  }

	async loadContractInstance(
    abi,
    address,
  ){
    if (!this.provider) {
      throw new Error('Metamask is not connected');
    }
    if (abi == undefined) {
      throw new Error('ABI is not passed as argument');
    }
    if (address == undefined) {
      throw new Error('address is not passed as argument');
    }
    const contract = new ethers.Contract(
      address,
      abi,
      this.provider.getSigner(0),
    );
    return contract;
  }

	async getERC1155RGInstance(){
		if(!this.erc1155RGContractInstance){
			this.erc1155RGContractInstance=await this.loadContractInstance(ERC1155RG.abi,process.env.REACT_APP_ERC1155RG_CONTRACT);
		}
		return this.erc1155RGContractInstance;
	}

	async getUSDCInstance(){
		if(!this.maticContractInstance){
			this.maticContractInstance=await this.loadContractInstance(IERC20.abi,process.env.REACT_APP_USDC_CONTRACT);
		}
		return this.maticContractInstance;
	}

	async getGameContractInstance(){
		if(!this.gameContractAddrInstance){
			this.gameContractAddrInstance=await this.loadContractInstance(IERC1155Game.abi,process.env.REACT_APP_GAME_CONTRACT);
		}
		return this.gameContractAddrInstance;
	}

	async getStakingContractInstance(){
		if(!this.stakingContractInstance){
			this.stakingContractInstance=await this.loadContractInstance(StakingRewards.abi,process.env.REACT_APP_STAKING_CONTRACT);
		}
		return this.stakingContractInstance;
	}

	async switchEthereumChain(){
		if(window.ethereum){
			try {
				console.log(ethers.BigNumber.from(SUPPORTED_CHAINS[0]).toHexString());
				await window.ethereum.request({
					method: 'wallet_switchEthereumChain',
					params: [{ chainId: ethers.BigNumber.from(SUPPORTED_CHAINS[0]).toHexString()}],
				});
			} catch (error) {
				if (error.code === 4902) {
					try {
						await window.ethereum.request({
							method: 'wallet_addEthereumChain',
							params: [{ chainId: ethers.BigNumber.from(SUPPORTED_CHAINS[0]).toHexString()}],
						});
					} catch (addError) {
						console.log(addError);
						throw addError;
					}
				}
			}
		}
	}
	async approveERC1155Delegation(contractAddr){
		const tokenContract = await this.getERC1155RGInstance();
		const tx=await tokenContract.functions.setApprovalForAll(contractAddr,true);
		return tx;
	}

	async isApprovalForAll(contractAddr){
		const tokenContract = await this.getERC1155RGInstance();
		const tx=await tokenContract.functions.isApprovedForAll(this.currentAccount,contractAddr);
		return tx;
	}

	async approveUSDCDelegation(amount){
		const tokenContract=await this.getUSDCInstance();
		const tx=await tokenContract.functions.approve(process.env.REACT_APP_GAME_CONTRACT,amount);
		return tx;
	}

	async buyFixedPriceIngredient(tokenId,paymentTokenId){
		const gameContract=await this.getGameContractInstance();
		const tx=await gameContract.functions.buyIngredient1NFT(tokenId.toString(),paymentTokenId.toString());
		return tx;
	}

	async buyVariablePriceIngredient(tokenId,purchaseTokenId){
		const gameContract=await this.getGameContractInstance();
		const tx=await gameContract.functions.buyIngredient2NFT(tokenId,purchaseTokenId);
		return tx;
	}

	async cookDish(tokenId,data){
		const gameContract=await this.getGameContractInstance();
		const tx=await gameContract.functions.cookDish(tokenId,this.currentAccount,data);
		return tx;
	}

	async getDishIngredients(tokenId){
		const gameContract=await this.getGameContractInstance();
		const tx=await gameContract.functions.getBurnTokensDish(tokenId);
		return tx;
	}

	async getTokenBalance(tokenId){
		const tokenContract=await this.getERC1155RGInstance();
	 	const tx=await tokenContract.functions.balanceOf(this.currentAccount,tokenId);
		return tx;
	}

	async getMultiTokenBalance(tokens){
		const accounts=[];
		const tokenContract=await this.getERC1155RGInstance();
		tokens.map(()=>accounts.push(this.currentAccount));
		const tx=await tokenContract.functions.balanceOfBatch(accounts,tokens);
		return tx;
	}

	async getAllTokensOwned(){
		const tokenContract=await this.getERC1155RGInstance();
		const tx=await tokenContract.functions.getAllTokensOwned(this.currentAccount);
		return tx[0].map((num)=>num.toString());
	}

	async getTokenType(id){
		const gameContract=await this.getGameContractInstance();
		const tx=await gameContract.functions.getTokenType(id);
		return tx;
	}

	async getCurrentPrice(id){
		const gameContract=await this.getGameContractInstance();
		const tx=await gameContract.functions.getCurrentPrice(id);
		return tx;
	}

	async stakeTokens(tokenId,quantity){
		const stakingContract=await this.getStakingContractInstance();
		const tx=await stakingContract.stake(tokenId,quantity);
		return tx;
	}

	async getStakedTokens(){
		const stakingContract=await this.getStakingContractInstance();
		const tx=await stakingContract.getStakedTokenIds(this.currentAccount);
		return tx.map((num)=>num.toString());
	}

	async unstakeTokens(tokenId,quantity){
		const stakingContract=await this.getStakingContractInstance();
		const tx=await stakingContract.withdraw(tokenId,quantity);//Quantity is 1 since only 1 dish can exist
		return tx;
	}

	async getRewardsBalance(){
		const stakingContract=await this.getStakingContractInstance();
		const tx=await stakingContract.earned(this.currentAccount);
		return FixedNumber.from(ethers.utils.formatUnits(tx,18)).round(4).toString();
	}

	async claimRewardTokens(){
		const stakingContract=await this.getStakingContractInstance();
		const tx=await stakingContract.getReward();
		return tx;
	}

}

const ethersServiceProvider=new EthersServiceProvider();

export default ethersServiceProvider;
