import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Typography,
  Divider,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import CustomButton from '../resuableComponents/CustomButton';
import { theme } from '../../App';
import { useHistory } from 'react-router-dom';
import {
  setConfirmTransactionModal,
  setError,
  setLoading,
  setModalOpen,
} from '../../reducers/UiReducer';
import gameService from '../../services/gameService';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'black',
    height: 2,
    width: 180,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'black',
  },
}));

const AdminItem = ({ item, index }) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  const buyItem = async () => {
    try {
      dispatch(setConfirmTransactionModal());
      const purchaseTx = await gameService.purchaseIngredient(
        item.type,
        item.tokenId,
        item.price
      );
      dispatch(setConfirmTransactionModal());
      dispatch(setLoading(true));
      await purchaseTx.wait();
    } catch (err) {
      dispatch(setError(true));
    }
    dispatch(setModalOpen());
    dispatch(setLoading(false));
  };

  return (
    <Box
      textAlign='center'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <img
        src={item.image}
        alt='ingredient icon'
        style={{ marginBottom: theme.spacing(1.5), width: xs ? '80%' : '100%' }}
      />
      <Tooltip
        title={item.name}
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <Typography variant='h4' style={{ marginBottom: theme.spacing(1.5) }}>
          {item.name.toUpperCase()}
        </Typography>
      </Tooltip>
      <CustomButton
        style={{ marginBottom: theme.spacing(1.5), backgroundColor: '#000000' }}
        textStyle={{
          color: theme.palette.primary.main,
          paddingRight: sm ? 5 : 20,
          paddingLeft: sm ? 5 : 20,
          fontSize: xs && 12,
        }}
        onClick={buyItem}
        disabled
      >
        BUY NOW
      </CustomButton>
      <CustomButton
        onClick={() => history.push(`/ingredients/${item.tokenId}`)}
        info
        style={{ backgroundColor: '#b2b3b3' }}
        textStyle={{
          color: '#fff',
          paddingRight: sm ? 5 : 20,
          paddingLeft: sm ? 5 : 20,
          fontSize: xs && 12,
        }}
      >
        VIEW INFO
      </CustomButton>
    </Box>
  );
};

export default AdminItem;
