import React, { useEffect } from 'react';
import {
  Grid,
  Box,
  makeStyles,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import AdminItem from './AdminItem';
import { theme } from '../../App';
import CustomButton from '../resuableComponents/CustomButton';
import RecipesItemsSection from '../recipesComponents/RecipesItemsSection';
import RecipesItem from '../recipesComponents/RecipesItem';
import {getTokensOwned} from "../../reducers/UiReducer";
import { useMetamask } from '../hooks/useMetamask';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const AdminItemsSection = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { purchasedIngredient, selectedDashboard } = store.ui;
  const matches = useMediaQuery('(min-width:940px)');
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const { purchasedRecipe } = store.ui;

  return (
    <Grid container spacing={!xs && 10}>
      {selectedDashboard === 'Ingredients'
        ? purchasedIngredient.map((item, index) => (
            <Grid item xs={6} sm={4} key={item.name}>
              <AdminItem item={item} index={index} />
            </Grid>
          ))
        : purchasedRecipe.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={item.name}>
              <RecipesItem item={item} index={index} />
            </Grid>
          ))}
    </Grid>
  );
};

export default AdminItemsSection;
