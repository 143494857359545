import React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { Web3ReactProvider } from '@web3-react/core';
import store from './Store';
import {
  createMuiTheme,
  ThemeProvider,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import { HelmetProvider } from 'react-helmet-async';
import { CssBaseline } from '@material-ui/core';
import MainRouter from './components/MainRouter';
import ethersServiceProvider from './services/ethersServiceProvider';

export let theme = createMuiTheme({
  palette: {
    primary: {
      // light: '#000000',
      main: '#D1FF1A',
      // dark: '#cccccc',
      contrastText: '#000000',
    },
    secondary: {
      // light: '#ffffff',
      main: '#000000',
      // dark: '#ffffff',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#000000',
      secondary: '#ffffff',
    },
    background: {
      default: '#fff',
      footerGrey: '#D7D7D7',
    },
    pink: {
      main: '#FF43CA',
    },
    green: {
      main: '#D1FF1A',
    },
    cyan: {
      main: '#2BFFF2',
    },
  },
  typography: {
    fontFamily: 'Karla, Roboto, sans-serif',
    h1: {
      fontFamily: 'Shrikhand',
      fontSize: '3.125rem',
    },
    h2: {
      fontFamily: 'Shrikhand',
      fontSize: '2.1875rem',
    },
    h3: {
      fontFamily: 'Shrikhand',
      fontSize: '1.75rem',
    },
    h4: {
      fontFamily: 'Karla',
      fontSize: '1.75rem',
      fontWeight: 'bold',
    },
    h5: {
      fontFamily: 'Karla',
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
    h6: {
      fontFamily: 'Karla',
      fontSize: '1.375rem',
      fontWeight: 'normal',
    },
    subtitle1: {
      fontFamily: 'Karla',
      fontSize: '1.25rem',
      fontWeight: 'bold',
    },
    body1: {
      fontFamily: 'Karla',
      fontSize: '1.125rem',
    },
    body2: {
      fontFamily: 'Karla',
      fontSize: '1rem',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

theme = responsiveFontSizes(theme);

function App() {
  return (
    <Web3ReactProvider
      getLibrary={() => ethersServiceProvider.getProvider()}
    >
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <HelmetProvider>
            <CssBaseline />
            <MainRouter />
          </HelmetProvider>
        </ThemeProvider>
      </Provider>
    </Web3ReactProvider>
  );
}

export default App;
